



import { defineComponent, onMounted, useRoute, useRouter, useStore, computed } from '@nuxtjs/composition-api'
import { useAuth } from '~/use/user/useAuth'
import { useMessage } from '~/use/useMessage'

export default defineComponent({
  name: 'MagicLink',
  components: {},
  layout: 'viewport',
  setup () {
    const { loginUser } = useAuth()
    const { showError } = useMessage()
    const router = useRouter()
    const route = useRoute()
    const store = useStore() as any
    const isFitted = computed(() => store.getters['user/isFitted'])

    onMounted(async () => {
      try {
        const { query } = route.value
        const { weight, height, token } = query

        await loginUser(token as string)


        if (isFitted.value) {
          router.push('/customer/shop')
        } else {
          router.push('/fit-flow')
        }
      } catch (err) {
        showError({ err, notify: true })
      }
    })

    return {}
  },
  head: {
    meta: [
      {
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow, noarchive',
      },
    ],
  },
})
