






























import { defineComponent, computed, useStore } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'

export default defineComponent({
  name: 'ShopHelpOverlay',
  components: {
    Overlay,
  },
  setup() {
    const store = useStore() as any

    const isVisible = computed<boolean>({
      get() {
        return store.state.overlay === 'shop-help'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'shop-help' : '')
      },
    })

    return {
      isVisible,
    }
  },
})
