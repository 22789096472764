import { render, staticRenderFns } from "./Guarantee.vue?vue&type=template&id=656a457a&scoped=true&"
import script from "./Guarantee.vue?vue&type=script&lang=js&"
export * from "./Guarantee.vue?vue&type=script&lang=js&"
import style0 from "./Guarantee.vue?vue&type=style&index=0&id=656a457a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "656a457a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,PageH2: require('/home/circleci/project/src/components/typography/PageH2.vue').default})
