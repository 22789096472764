

















































import { defineComponent, computed, useStore } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'

export default defineComponent({
  name: 'WelcomeScreen',
  components: {
    Overlay,
  },
  setup() {
    const store = useStore() as any

    const isVisible = computed<boolean>({
      get() {
        return store.state.overlay === 'gift-card-help'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'gift-card-help' : '')
      },
    })

    const info = [
      {
        title: 'Select the amount you would like to gift',
        subtitle: 'Select the amount you would like to purchase and add your gift card to your cart.',
      },
      {
        title: 'After checkout, we will email you the Gift Card',
        subtitle:
          'Once you have completed the checkout and paid, the digital gift card will be activated and sent to you via email.',
      },
      {
        title: 'Share it with your Recipient',
        subtitle:
          'Share the digital gift card with your desired recipient whenever you wish by simply forwarding the email. Or, if you’d like, print the gift card out, wrap it up and gift it in person.',
      },
      {
        title: 'Recipient uses the Gift Card balance to shop his custom fit & size',
        subtitle:
          'To use the gift card, your recipient will first complete our virtual fitting on our website; then, shop from a selection of styles, colors and fabrics; and lastly, enter the gift card at checkout to use the balance on the card.',
      },
      {
        title: 'You’ll both be happy he’s upgraded to Proportion',
        subtitle:
          'We will cut and sew his t-shirt(s) to order in his custom fit and size, and ship them within 3-5 business days from checkout. He’ll be looking sharp in no time.',
      },
    ]

    return {
      isVisible,
      info,
    }
  },
})
