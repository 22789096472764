

























































import { defineComponent, computed, useStore } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'
import Cart from '~//components/shop/cart/Cart.vue'
import SvgTimesIcon from '~/components/icons/SvgTimesIcon.vue'

export default defineComponent({
  name: 'CartOverlay',
  components: {
    Overlay,
    Cart,
    SvgTimesIcon,
  },
  setup() {
    const store = useStore() as any
    const isFitted = computed(() => store.getters['user/isFitted'])
    const isAuth = computed(() => !!store.getters.token)
    const hasCheckout = computed(() => store.getters['cart/checkoutId'])

    const _show = computed<boolean>({
      get() {
        return store.state.overlay === 'cart'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'cart' : '')
      },
    })

    function showAuthOverlay() {
      store.commit('SET_OVERLAY', 'login')
    }

    return {
      isFitted,
      isAuth,
      _show,
      showAuthOverlay,
      hasCheckout,
    }
  },
})
