import { render, staticRenderFns } from "./CollectionCard.vue?vue&type=template&id=ddab1592&"
import script from "./CollectionCard.vue?vue&type=script&lang=ts&"
export * from "./CollectionCard.vue?vue&type=script&lang=ts&"
import style0 from "./CollectionCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,IconsCircleIcon: require('/home/circleci/project/src/components/icons/CircleIcon.vue').default,IconsSvgTimesIcon: require('/home/circleci/project/src/components/icons/SvgTimesIcon.vue').default,PButton: require('/home/circleci/project/src/components/base/PButton.vue').default,IconsColorIcon: require('/home/circleci/project/src/components/icons/ColorIcon.vue').default})
