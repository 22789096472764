

















import {
  defineComponent,
  computed,
  onMounted,
  useStore,
  watch,
  useRoute,
  ref,
  useContext,
} from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'DefaultLayout',
  setup () {
    const { $device } = useContext() as any
    const route = useRoute()
    const store = useStore()
    const isFitted = computed(() => store.getters['user/isFitted'])
    const pageSettings = computed(() => store.getters['page/getPageSettings'])

    onMounted(() => {})

    const routeFrom = ref(null)

    watch(
      () => route.value,
      (next, prev) => {
        store.commit('SET_OVERLAY', '')
        if (prev) {
          routeFrom.value = prev as any
        }
      },
      { immediate: true },
    )

    const hasBackBtn = computed(() => {
      return (
        !$device.isMobile &&
        !!routeFrom.value &&
        ['products-slug', 'customer-shop', 'collection'].includes(route.value.name || '')
      )
    })

    return {
      pageSettings,
      hasBackBtn,
      isFitted,
    }
  },
  head: {},
})
