//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useStore, computed, useRouter, onMounted, ref } from '@nuxtjs/composition-api'
import { useAuth } from '~/use/user/useAuth'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  name: 'Account',
  setup() {
    const store = useStore()
    const fit = computed(() => store.getters['user/fit'])
    const size = computed(() => store.getters['user/size'])

    usePageSettings()
    function showDetails() {
      store.commit('SET_OVERLAY', 'welcome')
    }

    const { navigationGuard } = useAuth()
    const isPageLoading = ref(true)

    onMounted(() => {
      navigationGuard(() => (isPageLoading.value = false))
    })

    return {
      isPageLoading,
      fit,
      size,
      showDetails,
    }
  },
})
