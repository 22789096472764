






















































































import { defineComponent, computed, useStore, useRouter } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'
import FitSampleInfo from '~/components/base/FitSampleInfo.vue'
import MeasurementsTable from '~/components/shop/MeasurementsTable.vue'

export default defineComponent({
  name: 'WelcomeScreen',
  components: {
    Overlay,
    FitSampleInfo,
    MeasurementsTable,
  },
  props: {},
  setup() {
    const router = useRouter()
    const store = useStore() as any
    const fit = computed(() => store.getters['user/fit'])
    const size = computed(() => store.getters['user/size'])
    const firstName = computed(() => store.getters['user/firstName'])
    const isVisible = computed<boolean>({
      get() {
        return store.state.overlay === 'welcome'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'welcome' : '')
      },
    })

    function goToShop() {
      isVisible.value = false
      router.push('/customer/shop')
    }

    return {
      isVisible,
      fit,
      size,
      firstName,
      goToShop,
    }
  },
})
