//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, watch, ref, useStore, computed } from '@nuxtjs/composition-api'

import gsap from 'gsap'
import { useCart } from '~/use/useCart'

export default defineComponent({
  name: 'CartBtn',
  setup() {
    const store = useStore()
    const colors = computed(() => store.getters['page/getColors'])
    const cartOverlay = computed(() => store.state.overlay === 'cart')
    const checkoutId = computed(() => store.getters['cart/checkoutId'])

    const toggleCartOverlay = () => {
      store.commit('SET_OVERLAY', cartOverlay.value ? '' : 'cart')
    }

    const { totalQuantity, getCheckout } = useCart()

    const isFitted = computed(() => store.getters['user/isFitted'])
    watch(
      () => isFitted.value,
      (next, prev) => next && !prev && getCheckout(),
      { immediate: true },
    )

    watch(
      () => checkoutId.value,
      (next, prev) => next && !prev && getCheckout(),
      { immediate: true },
    )

    const tween = ref(null)
    const animationTimeout = ref(null)
    const displayQty = ref(0)

    function animateQuantity() {
      if (tween.value) {
        tween.value.restart()
      } else {
        tween.value = gsap.to('#qty-value .qty-value-bg', { scale: 1.2, ease: 'back.out(3)' })
      }
    }

    watch(
      () => totalQuantity.value,
      (next) => {
        clearTimeout(animationTimeout.value)
        if (next) {
          animationTimeout.value = setTimeout(() => {
            animateQuantity()
            displayQty.value = next
          }, 3000)
        } else {
          displayQty.value = 0
        }
      },
      { immediate: true },
    )

    return {
      displayQty,
      toggleCartOverlay,
      colors,
    }
  },
})
