























































import { defineComponent, ref, onMounted, onUnmounted, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CollectionCardImages',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const images = computed(() => props.images)

    const activeIndex = ref(0)
    const nextIndex = computed(() => {
      return activeIndex.value + 1 >= images.value.length ? 0 : activeIndex.value + 1
    })
    const prevIndex = computed(() => {
      return activeIndex.value - 1 < 0 ? images.value.length - 1 : activeIndex.value - 1
    })

    let autoplayTimeout: any = null

    function prevSlide() {
      activeIndex.value = prevIndex.value
      // autoplay()
    }

    function nextSlide() {
      activeIndex.value = nextIndex.value
      // autoplay()
    }

    function goToSlide(index: number) {
      activeIndex.value = index
      // autoplay()
    }

    function autoplay() {
      clearTimeout(autoplayTimeout)
      autoplayTimeout = setTimeout(nextSlide, 5000)
    }

    onMounted(() => {
      // autoplay()
    })

    onUnmounted(() => {
      clearTimeout(autoplayTimeout)
    })

    return {
      activeIndex,
      prevIndex,
      nextIndex,
      nextSlide,
      prevSlide,
      goToSlide,
    }
  },
})
