






import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  name: 'FitFlowLayout',
  components: {},
  setup () {
    return {

    }
  },
})
