











import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CTAToast',
  emits: ['dismissed'],
  setup (props, { emit }) {
    function dismiss () {
      emit('dismissed')
    }

    return {
      dismiss,
    }
  },
})
