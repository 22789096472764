//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const items = [
      {
        author: 'Michael F.',
        text: 'My Proportion t-shirts fit me exactly how I have always wanted. All of the fabrics are insane. They feel amazing, and don’t shrink. What more could you possibly want?',
      },
      {
        author: 'Jaden R.',
        text: 'These are seriously the best t-shirts I have ever purchased.',
      },
      {
        author: 'Daniel T.',
        text: "Virtual Fitting was long at first, but totally worth it. I only wear these t's now and re-ordering my custom t's is so easy.",
      },
    ]
    const activeIndex = ref(0)
    const nextIndex = computed(() => {
      return activeIndex.value + 1 >= items.length ? 0 : activeIndex.value + 1
    })

    let autoplayTimeout = null

    function nextSlide() {
      activeIndex.value = nextIndex.value
      autoplay()
    }

    function autoplay() {
      clearTimeout(autoplayTimeout)
      autoplayTimeout = setTimeout(nextSlide, 10000)
    }

    onMounted(() => {
      autoplay()
    })

    onUnmounted(() => {
      clearTimeout(autoplayTimeout)
    })

    return {
      items,
      activeIndex,
    }
  },
})
