































import { defineComponent, ref, Ref, computed, watch, useStore } from '@nuxtjs/composition-api'

import Clipboard from 'clipboard'
import { useDelay } from '~/use/useDelay'

export default defineComponent({
  name: 'RequiredBrowser',
  components: {},
  props: {
    browser: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore() as any
    const token = computed(() => store.getters.token)

    const copyText = computed(() => `${process.env.BASE_URL}/magic-link?token=${token.value}`)

    const btnRef = ref<Ref | null>(null)
    const isCopied = ref(false)

    const browserImageSrc = computed(() => (props.browser === 'Chrome' ? '/img/chrome.png' : '/img/safari.png'))

    function _initClipBoard(btn: HTMLElement) {
      const clipboard = new Clipboard(btn)

      clipboard.on('success', async function (e) {
        isCopied.value = true
        await useDelay(2000)
        isCopied.value = false

        e.clearSelection()
      })

      clipboard.on('error', function (e) {
        console.log(e)
      })
    }

    watch(
      () => btnRef.value,
      (next) => {
        if (next) {
          _initClipBoard(next.$el)
        }
      },
      { immediate: true },
    )

    return {
      token,
      btnRef,
      copyText,
      isCopied,
      browserImageSrc,
    }
  },
})
