//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, useAsync, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {},
  setup() {
    // const featuredProducts = useAsync(() => {
    //   try {
    //     const products = [
    //       require('~/static/generated/products/fit-02-crewneck-short-sleeve-medium-weight.json'),
    //       require('~/static/generated/products/fit-02-side-split-short-sleeve-lightweight-cotton.json'),
    //       require('~/static/generated/products/fit-02-crewneck-long-sleeve-heavy-weight.json'),
    //     ]
    //     return products
    //   } catch (err) {
    //     notifyError(err)
    //   }
    // })

    const featuredProducts = computed(() => [
      {
        image: '/v2/collection/fabric-medium-weight-image-01 1.jpg',
        style: 'Crewneck',
        sleeve: 'Short',
        fabric: 'Medium Weight',
        color: 'Optic White',
        price: 78,
      },
      {
        image: '/v2/collection/IMG_9296 1.jpg',
        style: 'Side Split',
        sleeve: 'Short',
        fabric: 'Lightweight',
        color: 'Desert Sage',
        price: 88,
      },
      {
        image: '/v2/collection/fabric-lightweight-image-01.jpg',
        style: 'Crewneck',
        sleeve: 'Long',
        fabric: 'Heavyweight',
        color: 'Optic White',
        price: 118,
      },
    ])
    return {
      featuredProducts,
    }
  },
})
