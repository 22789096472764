








import { defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Overlay',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    // const route = useRoute()
    // const scrollPosition = ref(0)

    onMounted(() => {
      // watch(
      //   () => props.show,
      //   (next) => {
      //     if (next) {
      //       scrollPosition.value = window.pageYOffset
      //       document.body.style.top = -scrollPosition.value + 'px'
      //       document.body.classList.add('noscroll')
      //     } else {
      //       document.body.style.top = '0'
      //       document.body.classList.remove('noscroll')
      //       window.scrollTo({ top: scrollPosition.value })
      //     }
      //   },
      // )
    })

    onUnmounted(() => {
      emit('close')
    })

    return {}
  },
})
