






































































































































































import { defineComponent, ref, useMeta, onMounted, onUnmounted, useRoute, computed } from '@nuxtjs/composition-api'
import { getImagePathCDN } from '~/utils/shopify-helpers'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  name: 'Fitting',
  setup() {
    const route = useRoute()

    useMeta({
      title: 'VIRTUAL FITTING',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })
    usePageSettings()
    onMounted(() => {})

    onUnmounted(() => {})

    const videInstructionFile = getImagePathCDN('/new-site-images/3. virtual fitting/video-instructions.mp4')

    function playVideo() {
      // await useDelay(300)
      const videoElement = document.getElementById('vf-video-player') as any

      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen()
      } else if (videoElement.webkitEnterFullScreen) {
        videoElement.webkitEnterFullScreen()
      }
      try {
        videoElement.play()
      } catch (err) {
        console.log(err)
      }
    }
    return {
      playVideo,
      videInstructionFile,
    }
  },
  head: {},
})
