


































import { defineComponent, computed, useStore } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'

type FabricCardData = {
  title: string,
  price: number,
  contents: string,
  weight: string,
  qualities: string
  image: string
}

export default defineComponent({
  name: 'FabricOverlay',
  components: {
    Overlay,
  },
  setup() {
    const store = useStore() as any

    const isVisible = computed<boolean>({
      get() {
        return store.state.overlay === 'fabric-help'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'fabric-help' : '')
      },
    })

    const fabrics: FabricCardData[] = [
      {
        title: 'LIGHTWEIGHT COTTON',
        price: 98,
        contents: '100% Cotton',
        weight: '5.8 oz / 112 GSM',
        qualities: 'Breathable, soft but drier hand, inspired by washed and aged linen.',
        image: '/v2/fabric-light.jpg',
      },
      {
        title: 'Medium Weight Cotton',
        price: 98,
        contents: '100% Cotton',
        weight: '8 oz / 155 GSM',
        qualities:
          'Certified Supima Cotton, Sanded for ultimate softness and feel, Versatile, everyday + every occassion weight.',
        image: '/v2/fabric-medium.jpg',
      },
      {
        title: 'Heavyweight Cotton',
        price: 118,
        contents: '100% Cotton',
        weight: '12 oz / 225 GSM',
        qualities: 'Double knit for extra structure and weight.',
        image: '/v2/fabric-heavy.jpg'
      },
    ]

    return {
      isVisible,
      fabrics,
    }
  },
})
