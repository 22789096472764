//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useStore, watch, ref, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const store = useStore()
    const visible = computed(() => !store.getters['user/isFitted'])
    const $ctaRef = ref(null)
    const stuck = ref(false)
    const observer = ref(null)

    onMounted(() => {
      observer.value = new IntersectionObserver(
        ([e]) => {
          stuck.value = e.intersectionRatio < 1
        },
        {
          rootMargin: `-${window.innerHeight - 200}px 0px 0px 0px`,
          threshold: [1],
        },
      )
      watch(
        () => $ctaRef.value,
        (next) => {
          next && observer.value.observe(next)
        },
        { immediate: true },
      )
    })

    onUnmounted(() => {
      $ctaRef.value && observer.value && observer.value.unobserve($ctaRef.value)
    })

    return {
      visible,
      $ctaRef,
      stuck,
    }
  },
})
