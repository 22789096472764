














import {
  defineComponent,
  ref,
  onMounted,
  useMeta,
  useRoute,
  useRouter,
  useStore,
  computed,
} from '@nuxtjs/composition-api'

import { usePageSettings } from '~/use/usePage'
import { useAuth } from '~/use/user/useAuth'
import { useDelay } from '~/use/useDelay'

export default defineComponent({
  name: 'Homepage',
  middleware: ({ store, redirect }) => {
    if (process.client) {
      const isFitted = store.getters['user/isFitted']

      if (isFitted) {
        console.log('middleware redirect: shop')
        redirect('/customer/shop')
      }
    }
  },
  setup() {
    const { fetchUser, needsRefetchUser } = useAuth()
    const route = useRoute()
    const router = useRouter()
    const store = useStore() as any
    const isFitted = computed(() => store.getters['user/isFitted'])
    const isPageLoading = ref(true)

    usePageSettings({
      navColors: {
        default: {
          textColor: 'white',
        },
      },
    })
    useMeta({
      title: 'Proportion Clothing',
      titleTemplate: '%s',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })

    onMounted(async () => {
      if (needsRefetchUser.value) {
        await fetchUser()

        if (isFitted.value) {
          router.push('/customer/shop')
        }
      } else {
        await useDelay(300)
        isPageLoading.value = false
      }
    })

    return {
      isPageLoading,
    }
  },
  head: {},
})
