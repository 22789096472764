




import { useAsync, useContext, useMeta, defineComponent } from '@nuxtjs/composition-api'
import { usePageSettings } from '~/use/usePage'
import StaticPage from '~/components/base/StaticPage.vue'

export default defineComponent({
  components: { StaticPage },
  transition: {
    name: 'fade',
    mode: 'out-in',
  },
  setup() {
    const { params, $content, error, route, $sentry } = useContext() as any

    const staticPageTitle = 'Legal'
    const staticPageContentPath = 'legal'
    const content = useAsync(async () => {
      return await $content(staticPageContentPath, params.value.slug)
        .fetch()
        .catch((_err: Error) => {
          error({ statusCode: 404, message: 'Page not found' })
          $sentry.captureException(_err)
        })
    })

    const breadcrumbs = [
      { title: staticPageTitle, path: staticPageContentPath },
      { title: content.value?.title, path: params.value.slug },
    ]

    useMeta(() => {
      return {
        title: `${content.value?.title}`,
        meta: [
          {
            hid: 'og:url',
            name: 'og:url',
            content: `${process.env.BASE_URL}${route.value.path}`,
          },
        ],
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: `${process.env.BASE_URL}${route.value.path}`,
          },
        ],
      }
    })
    usePageSettings()

    return {
      title: staticPageTitle,
      content,
      breadcrumbs,
    }
  },
  head: {},
})
