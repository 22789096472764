//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useMeta, useContext } from '@nuxtjs/composition-api'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  setup() {
    const { route } = useContext()
    usePageSettings()
    useMeta({
      title: 'About Us',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })
  },
  head: {},
})
