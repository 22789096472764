import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=744ea779&scoped=true&"
import script from "./Cart.vue?vue&type=script&lang=ts&"
export * from "./Cart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "744ea779",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartItem: require('/home/circleci/project/src/components/shop/cart/CartItem.vue').default,AnimatedNumber: require('/home/circleci/project/src/components/shop/cart/AnimatedNumber.vue').default,PButton: require('/home/circleci/project/src/components/base/PButton.vue').default,PIcon: require('/home/circleci/project/src/components/base/PIcon.tsx').default})
