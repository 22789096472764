






























import {
  defineComponent,
  onMounted,
  useMeta,
  ref,
  useRoute,
  useStore,
  computed,
  useContext,
} from '@nuxtjs/composition-api'

import { useAuth } from '~/use/user/useAuth'
import { useDelay } from '~/use/useDelay'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  name: 'PersonalShopPage',
  setup() {
    const { $gtag } = useContext() as any
    const store = useStore() as any
    const route = useRoute()

    useMeta({
      title: 'Personal Shop',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })
    usePageSettings()

    const { navigationGuard } = useAuth()

    const isPageLoading = ref(true)
    const welcomeScreen = computed(() => store.getters.welcomeScreen)
    const fit = computed(() => store.getters['user/fit'])

    onMounted(() => {
      navigationGuard(async () => {
        isPageLoading.value = false

        if (!welcomeScreen.value) {
          await useDelay(3000)
          store.commit('SET_OVERLAY', 'welcome')
          store.commit('SET_STATE_PERSIST', { welcomeScreen: true })
        }
        $gtag.event('signed_in_collection')
      })
    })

    const ctaHelpVisible = ref(true)
    function showHelpOverlay() {
      store.commit('SET_OVERLAY', 'shop-help')
    }

    return {
      isPageLoading,
      fit,
      ctaHelpVisible,
      showHelpOverlay,
    }
  },
  head: {},
})
