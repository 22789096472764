//
//
//
//
//
//

import { defineComponent, ref, onMounted, useStore, computed } from '@nuxtjs/composition-api'

import QRCode from 'qrcode'

export default defineComponent({
  name: 'QRCode',
  setup() {
    const $canvasRef = ref(null)

    onMounted(() => {
      const store = useStore()
      const token = computed(() => store.getters.token)

      const toQR = `${process.env.BASE_URL}/magic-link?token=${token.value}`

      QRCode.toCanvas(
        $canvasRef.value,
        toQR,
        {
          type: 'image/jpeg',
          margin: 0,
          scale: 3,
          color: {
            dark: '#000000',
            light: '#f0f0f0',
          },
        },
        function (error) {
          if (error) {
            console.error(error)
          }
        },
      )
    })

    return {
      $canvasRef,
    }
  },
})
