//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, ref, onMounted } from '@nuxtjs/composition-api'

import SVGGrade from './SVGComponents/SVGGrade.vue'
import SVGGradePointer from './SVGComponents/SVGGradePointer.vue'
import SVGWarning from './SVGComponents/SVGWarning.vue'
import SvgChevronLeft from '~/components/icons/SvgChevronLeft.vue'
import SvgTimesIcon from '~/components/icons/SvgTimesIcon.vue'

export default defineComponent({
  name: 'CameraHUDSimple',
  components: {
    SvgChevronLeft,
    SvgTimesIcon,

    SVGGrade,
    SVGGradePointer,
    SVGWarning,
  },
  props: {
    gyroscopePosition: {
      type: Number,
      default: 0,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    timer: {
      type: Number,
      default: 0,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['ready', 'close'],
  setup(props, { emit }) {
    const instructionsVisible = ref(false)

    const gradeStyle = computed(() => ({ transform: `translate3d(0px, -${props.gyroscopePosition}px, 0px)` }))

    function showInstructions(visible) {
      instructionsVisible.value = visible
    }

    function goLive() {
      emit('ready')
    }

    function goBack () {
      emit('close')
    }

    onMounted(() => {
      showInstructions(true)
    })

    return {
      gradeStyle,
      showInstructions,
      instructionsVisible,
      goBack,
      goLive,
    }
  },
})
