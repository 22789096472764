





























import { defineComponent, ref, computed, onMounted, watch, PropType } from '@nuxtjs/composition-api'
import { CollectionOptionCustom } from '~/use/user/useCollection'

export default defineComponent({
  name: 'CollectionOptions',
  props: {
    title: {
      type: String,
      default: '',
    },
    defaultTitle: {
      type: String,
      default: 'Select Option',
    },
    selected: {
      type: Object as PropType<CollectionOptionCustom>,
      default: null,
    },
  },
  setup(props) {
    const expanded = ref(false)
    const dropdownRef = ref(null)
    const dropdownHeight = ref(0)
    const dropdownStyle = computed(() => ({ height: expanded.value ? `${dropdownHeight.value}px` : 0 }))

    function computeHeight(container: any) {
      dropdownHeight.value = container.getBoundingClientRect().height
    }

    function expand() {
      expanded.value = true
    }
    function collapse() {
      expanded.value = false
    }
    function toggle() {
      expanded.value = !expanded.value
    }

    watch(
      () => dropdownRef.value,
      (next, prev) => next && computeHeight(next),
      { immediate: true },
    )

    watch(
      () => props.selected,
      (next, prev) => next !== prev && collapse(),
    )

    onMounted(() => {})

    return {
      dropdownRef,
      dropdownStyle,
      expanded,
      expand,
      collapse,
      toggle,
    }
  },
})
