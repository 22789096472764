








import {
  useContext,
  useMeta,
  defineComponent,
  computed,
  watch,
  ref,
  onMounted,
  useStore,
  useRoute,
  Ref,
  useAsync,
} from '@nuxtjs/composition-api'

import { useAuth } from '~/use/user/useAuth'
import { useMessage } from '~/use/useMessage'
import { usePageSettings } from '~/use/usePage'
import { IProduct } from '~/services/api/db-types-shop'

import { getSrc } from '~/utils/shopify-helpers'

function getMeta(product: IProduct): any {
  const src = product.images && product.images[0] && product.images[0].src
  const image = getSrc(src)
  const metaData = {
    title: product.seo.title || product.title,
    description: product.seo.description || product.description,
    image,
  }

  return {
    title: metaData.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: metaData.description,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        content: metaData.title,
      },
      {
        hid: 'og:type',
        name: 'og:type',
        content: 'product',
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: metaData.description,
      },
      ...(metaData.image
        ? [
            {
              hid: 'image',
              name: 'image',
              content: metaData.image,
            },
            {
              hid: 'og:image',
              name: 'og:image',
              content: metaData.image,
            },
          ]
        : []),
    ],
  }
}

function updateMeta(head: any, meta: any) {
  for (const [key, value] of Object.entries(meta)) {
    head[key].value = value
  }
}

export default defineComponent({
  name: 'Product',
  components: {
    public: () => import('~/components/views/ProductView.vue'),
    personal: () => import('~/components/views/PerosnalProductView.vue'),
  },
  setup() {
    const { $gtag } = useContext() as any
    const head = useMeta()
    const route = useRoute()
    const { showError, notifyError } = useMessage()
    const { fetchUser } = useAuth()

    const store = useStore() as any

    const token = computed(() => store.getters.token)
    const isFitted = computed(() => store.getters['user/isFitted'])
    const component = computed(() => (isFitted.value ? 'personal' : 'public'))

    const isPageLoading = ref(true)

    const currentProduct = useAsync(() => {
      try {
        const product = require(`~/static/generated/products/${route.value.params.slug}.json`)
        return product
      } catch (err) {
        notifyError(err)
      }
    }, route.value.fullPath)

    watch(
      () => currentProduct.value,
      (next, prev) => {
        if (next) {
          const { title, id, collectionNumber } = next

          updateMeta(head, getMeta(next))

          $gtag.event('view_item', {
            items: [
              {
                item_name: title,
                item_id: id,
                item_category: collectionNumber,
              },
            ],
          })
        }
      },
      { immediate: true },
    )

    usePageSettings()

    onMounted(async () => {
      if (token.value && !isFitted.value) {
        try {
          await fetchUser()
        } catch (err) {
          showError({ err, notify: true })
        }
      }
      isPageLoading.value = false
    })

    return {
      isPageLoading,
      component,
      currentProduct,
    }
  },
  head: {},
})
