import { render, staticRenderFns } from "./GiftCardHelpOverlay.vue?vue&type=template&id=6ef56da8&"
import script from "./GiftCardHelpOverlay.vue?vue&type=script&lang=ts&"
export * from "./GiftCardHelpOverlay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Backdrop: require('/home/circleci/project/src/components/base/Backdrop.vue').default,PIcon: require('/home/circleci/project/src/components/base/PIcon.tsx').default,PButtonIcon: require('/home/circleci/project/src/components/base/PButtonIcon.vue').default,PLink: require('/home/circleci/project/src/components/base/PLink.vue').default,Overlay: require('/home/circleci/project/src/components/base/Overlay.vue').default})
