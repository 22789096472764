
















import { defineComponent } from '@nuxtjs/composition-api'

import LoadingDots from '~/components/base/LoadingDots.vue'

export default defineComponent({
  name: 'FitFlowAlgorithm',
  components: { LoadingDots },
  setup() {
    return {}
  },
})
