import { render, staticRenderFns } from "./fitting.vue?vue&type=template&id=2d19bc45&scoped=true&"
import script from "./fitting.vue?vue&type=script&lang=ts&"
export * from "./fitting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d19bc45",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageH1Title: require('/home/circleci/project/src/components/typography/PageH1Title.vue').default,PIcon: require('/home/circleci/project/src/components/base/PIcon.tsx').default,PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,ViewsFittingModelSpecs: require('/home/circleci/project/src/components/views/fitting/ModelSpecs.vue').default,PageH3: require('/home/circleci/project/src/components/typography/PageH3.vue').default,ViewsFittingSVGText: require('/home/circleci/project/src/components/views/fitting/SVGText.vue').default,NavCTAFitting: require('/home/circleci/project/src/components/nav/CTAFitting.vue').default,CTAMoreAbout: require('/home/circleci/project/src/components/base/CTAMoreAbout.vue').default})
