import { render, staticRenderFns } from "./PerosnalProductView.vue?vue&type=template&id=4f12215d&"
import script from "./PerosnalProductView.vue?vue&type=script&lang=ts&"
export * from "./PerosnalProductView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,PButton: require('/home/circleci/project/src/components/base/PButton.vue').default,ProductOrderDetails: require('/home/circleci/project/src/components/shop/product/ProductOrderDetails.vue').default,ProductCard: require('/home/circleci/project/src/components/shop/product/ProductCard.vue').default,PurchaseOverlay: require('/home/circleci/project/src/components/shop/PurchaseOverlay.vue').default})
