//
//
//
//
//
//
//
//
//

export default {
  name: 'SVGCrewneckLong',
}
