



































































import { defineComponent, reactive, computed, ref, Ref, useStore } from '@nuxtjs/composition-api'

import { IProduct, IProductOptionSelected, IProductVariant } from '~/services/api/db-types-shop'
import { useCart } from '~/use/useCart'
import { useDelay } from '~/use/useDelay'
import { CollectionOptionCustom } from '~/use/user/useCollection'

export default defineComponent({
  name: 'CollectionCardPersonal',
  props: {
    collectionData: {
      type: Object,
      required: true,
    },
  },
  emits: [],
  setup(props, { emit }) {
    const store = useStore() as any
    const { cartAddMany } = useCart()

    const priceRange = computed(() => {
      const p = props.collectionData.prices
      return p.length > 1 ? `$${Math.min(...p)} - $${Math.max(...p)}` : `$${p[0]}`
    })
    const variantsAvailale = computed(() => {
      const { colors, fabrics } = props.collectionData
      return colors.length * fabrics.length
    })
    const featuredImages = computed(() => props.collectionData.featured.images)
    const featuredMeta = computed(() => props.collectionData.featured.metafields)

    const selectedColors: Record<string, number> = reactive(
      props.collectionData.colors.reduce((colors: any, color: CollectionOptionCustom) => {
        colors[color.value] = 0
        return colors
      }, {}),
    )
    const selectedFabricOption: Ref<CollectionOptionCustom | null> = ref(null)
    const selectedProduct = computed(() =>
      props.collectionData.products.find((p: IProduct) => p.params.fabric === selectedFabricOption.value?.value),
    )

    const lineItems = computed(() => {
      const lineItems = []
      const entries = Object.entries(selectedColors).filter(([colorValue, qty]) => qty > 0)
      for (const [colorValue, qty] of entries) {
        const variant = selectedProduct.value.variants.find(
          (v: IProductVariant) =>
            v.selectedOptions.find((o: IProductOptionSelected) => o.name === 'color')?.value === colorValue,
        )
        if (variant) {
          lineItems.push({
            customAttributes: variant.selectedOptions.map(({ name, value }: IProductOptionSelected) => ({
              key: name,
              value,
            })),
            quantity: qty,
            variantId: variant.id,
          })
        }
      }
      return lineItems
    })

    function selectFabric(fabricOption: CollectionOptionCustom) {
      console.log('selectFabric', { ...fabricOption })
      selectedFabricOption.value = fabricOption
    }
    function selectQtyByColor(value: string, qty: number) {
      console.log('selectQtyByColor', value, qty)
      selectedColors[value] = qty
    }

    const loading = ref(false)

    async function addToCart() {
      loading.value = true
      console.log(lineItems.value)

      await cartAddMany(lineItems.value)
      await useDelay(1500)

      showCartMessage(`ADDED ${lineItems.value.length} ITEM${lineItems.value.length > 1 ? 'S' : ''} TO CART`)
      loading.value = false
    }

    const isCartDisabled = computed(() => loading.value || !lineItems.value.length)

    function showCartMessage(message: string) {
      store.commit('cart/SHOW_MESSAGE', message)
      setTimeout(() => store.commit('cart/SHOW_MESSAGE', ''), 10000)
    }

    function showFabricHelp() {
      store.commit('SET_OVERLAY', 'fabric-help')
    }

    return {
      priceRange,
      variantsAvailale,
      featuredImages,
      featuredMeta,

      showFabricHelp,
      selectFabric,
      selectQtyByColor,

      selectedFabricOption,

      isCartDisabled,
      addToCart,
      loading,
      showCartMessage,

    }
  },
})
