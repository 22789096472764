




































import { defineComponent, ref, computed, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'xl',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const params = {}

    const { primary, secondary, rounded, size, outlined, errorMessage } = toRefs(props)

    const isDefault = computed(() => !primary.value && !secondary.value)

    const className = computed(() => ({
      border: outlined.value || errorMessage.value,
      'border-current': true,
      'rounded-full': !!rounded.value,
      [`text-${size.value}`]: true,

      // errors
      'border-red-400': errorMessage.value,
      'text-red-400': errorMessage.value,

      // default
      'text-gray-600': isDefault.value && !errorMessage.value,
      'bg-gray-100': isDefault.value,
      'border-gray-100': isDefault.value && outlined.value,

      // primary
      'text-gray-300': primary.value && !errorMessage.value,
      'bg-gray-500': primary.value,
      // secondary
      'text-black': secondary.value && !errorMessage.value,
      'bg-white': secondary.value,
    }))

    const inputValue = ref(props.value)
    // const errors = ref(props.errorMessages)
    return {
      emit,
      ...params,
      className,
      inputValue,
    }
  },
})
