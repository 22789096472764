







import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api'
import { useEventListener } from '~/use/useEventListener'

export default defineComponent({
  name: 'ViewportBox',
  props: {
    withOffset: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  setup (props: any) {
    const computedHeight = ref(props.height + 'vh')

    function resizeHeight() {
      const height = window.innerHeight
      computedHeight.value = (height * props.height) / 100 + 'px'
    }

    useEventListener('resize', resizeHeight)

    onMounted(() => {
      resizeHeight()
    })

    return {
      computedHeight,
    }
  },
})
