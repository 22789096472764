//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ColorIcon',
  props: {
    color: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const backgroundImage = computed(() => props.image && `url(${props.image})`)
    const backgroundColor = computed(() => props.color)
    return {
      backgroundImage,
      backgroundColor,
    }
  },
})
