




















import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { useLiveChat } from '~/use/useLiveChat'
import GradientDotIcon from '~/components/icons/GradientDotIcon.vue'

export default defineComponent({
  name: 'PButtonLiveChat',
  components: { GradientDotIcon },
  props: {
    indicator: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { $device } = useContext()
    const isMobile = $device.isMobile
    const { openLiveChat, isLoading, isAvailable } = useLiveChat()

    return { openLiveChat, isLoading, isAvailable, isMobile }
  },
})
