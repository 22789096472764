
























import { defineComponent, computed, toRefs } from '@nuxtjs/composition-api'

import { useBtn } from '~/use/useBtn'
import SvgArrowRight from '~/components/icons/SvgArrowRight.vue'
import CircularLoading from '~/components/base/CircularLoading.vue'

const optionalBoolean = {
  type: Boolean,
  default: false,
  required: false,
}

export default defineComponent({
  name: 'VFNextStepButton',
  components: {
    SvgArrowRight,
    CircularLoading,
  },
  props: {
    disabled: optionalBoolean,
    loading: optionalBoolean,
    width: {
      type: String,
      default: '250px',
    },
    height: {
      type: String,
      default: '60px',
    },
  },
  setup(props, context) {
    const { isTruthy, component, onClick } = useBtn(props, context)
    const { loading } = toRefs(props)

    const computedClasses = computed(() => ({
      'is-loading': isTruthy(loading.value),
      // shape
      'with-arrow': true,
    }))

    return {
      component,
      computedClasses,
      onClick,
    }
  },
})
