





























































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CollectionCard',
  props: {
    collectionData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const popupIndex = ref(-1)

    function togglePopup(index: number) {
      if (popupIndex.value === index) {
        popupIndex.value = -1
      } else {
        popupIndex.value = index
      }
    }

    const priceRange = computed(() => {
      const p = props.collectionData.prices
      return p.length > 1 ? `$${Math.min(...p)} - $${Math.max(...p)}` : `$${p[0]}`
    })

    const variantsAvailale = computed(() => {
      const { colors, fabrics } = props.collectionData
      return colors.length * fabrics.length
    })

    const featuredImages = computed(() => props.collectionData.featured.images)
    const featuredMeta = computed(() => props.collectionData.featured.metafields)

    return {
      priceRange,
      variantsAvailale,
      popupIndex,
      featuredImages,
      featuredMeta,
      togglePopup,
    }
  },
})
