














import { defineComponent, computed, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'NavBtn',
  components: {},
  setup () {
    const store = useStore() as any
    const showDrawer = computed<boolean>({
      get () {
        return store.state.overlay === 'menu'
      },
      set (v) {
        store.commit('SET_OVERLAY', v ? 'menu' : '')
      },
    })
    const colors = computed(() => store.getters['page/getColors'])

    const toggleMenuMobileOverlay = () => {
      showDrawer.value = !showDrawer.value
    }

    return {
      colors,
      toggleMenuMobileOverlay,
      showDrawer,
    }
  },
})
