














































































































import {
  defineComponent,
  computed,
  PropType,
  useContext,
  useStore,
  ref,
  onMounted,
  useAsync,
} from '@nuxtjs/composition-api'

import { useMessage } from '~/use/useMessage'
import { PRODUCT_COLORS, IProductOptionColorValue } from '~/constants'
import { IProduct, IProductVariant } from '~/services/api/db-types-shop'
import { getProductTitle } from '~/utils/collectionHelpers'
import PurchaseOverlay from '~/components/shop/PurchaseOverlay.vue'
import ProductCard from '~/components/shop/product/ProductCard.vue'
import ProductOrderDetails from '~/components/shop/product/ProductOrderDetails.vue'

export default defineComponent({
  name: 'PersonalProductView',
  components: {
    ProductCard,
    PurchaseOverlay,
    ProductOrderDetails,
  },
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $gtag } = useContext() as any
    const { notifyError } = useMessage()
    const store = useStore() as any
    const fit = computed(() => store.getters['user/fit'])
    const size = computed(() => store.getters['user/size'])
    const productTitle = computed(() => getProductTitle(props.product))
    const colorValues = props.product.optionMaps.color.values as IProductOptionColorValue[]
    const colors = computed(() => colorValues.map((v: IProductOptionColorValue) => PRODUCT_COLORS[v]))

    const collection = useAsync(() => {
      try {
        const products = require(`~/static/generated/collections/fit-${fit.value}.json`)
        return products
      } catch (err) {
        notifyError(err)
      }
    })
    const otherProducts = computed(() => {
      return collection.value?.products.filter((p: IProduct) => p.handle !== props.product.handle) as IProduct[]
    })
    const price = computed(() =>
      props.product.variants
        .map((item: IProductVariant) => parseFloat(item.price))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a - b),
    )

    const cartMessage = computed(() => store.state.cart.cartMessage)
    function showCartMessage(message: string) {
      store.commit('cart/SHOW_MESSAGE', message)
      setTimeout(() => store.commit('cart/SHOW_MESSAGE', ''), 3000)
    }

    function showPurchaseOverlay() {
      $gtag.event('make_selection', {})
      store.commit('SET_OVERLAY', 'purchase')
    }

    onMounted(() => {
      $gtag.event('signed_in_product_view', { ...props.product.params })
    })

    return {
      showPurchaseOverlay,
      showCartMessage,
      cartMessage,
      productTitle,
      fit,
      size,

      colors,
      price,
      otherProducts,
    }
  },
})
