//
//
//
//
//
//

export default {
  name: 'CircularLoading',
}
