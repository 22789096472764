





import { useRouter, defineComponent, computed } from '@nuxtjs/composition-api'

const IPLinkProps = {
  to: {
    type: [String, Object] as any,
  },
}

export default defineComponent({
  name: 'PLink',
  props: IPLinkProps,
  emits: ['click'],
  setup (props, context) {
    const router = useRouter()
    const { to } = props as any

    const href = computed(() => (to ? router.resolve(to).href : '#'))

    function handleClick(e: any) {
      if (to) {
        router.push(to)
      } else {
        context.emit('click', e)
      }
    }

    return {
      handleClick,
      href,
    }
  },
})
