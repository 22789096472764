import { render, staticRenderFns } from "./FitFlowAuth.vue?vue&type=template&id=58043f40&"
import script from "./FitFlowAuth.vue?vue&type=script&lang=ts&"
export * from "./FitFlowAuth.vue?vue&type=script&lang=ts&"
import style0 from "./FitFlowAuth.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PInput: require('/home/circleci/project/src/components/base/PInput.vue').default,PLink: require('/home/circleci/project/src/components/base/PLink.vue').default,VFNextStepButton: require('/home/circleci/project/src/components/fit-flow/VFNextStepButton.vue').default})
