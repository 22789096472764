














import { onMounted, defineComponent, onUnmounted, computed, useStore, useAsync } from '@nuxtjs/composition-api'

import { useMessage } from '~/use/useMessage'

import { useCollection } from '~/use/user/useCollection'

export default defineComponent({
  name: 'CollectionView',
  setup() {
    // const store = useStore() as any
    const { notifyError } = useMessage()

    const { collections } = useCollection('02')

    return {
      collections,
    }
  },
})
