

























import { defineComponent, computed, PropType } from '@nuxtjs/composition-api'

import { CollectionOptionCustom } from '~/use/user/useCollection'

export default defineComponent({
  name: 'OptionFabric',
  props: {
    fabricData: {
      type: Object as PropType<CollectionOptionCustom>,
      required: true,
    },
  },
  emits: ['help', 'select'],
  setup(props, { emit }) {
    function selectOption() {
      emit('select', props.fabricData as CollectionOptionCustom)
    }

    return {
      selectOption,
    }
  },
})
