


































































































import { defineComponent, onMounted, ref, computed, useContext, useStore, watch } from '@nuxtjs/composition-api'

import { useDelay } from '~/use/useDelay'
import { useVirtualFitting } from '~/use/user/useVirtualFitting'
import { UserLengthPreference, UserFitPreference } from '~/services/api/db'
import { registerMeasurement } from '~/services/api/db-user'

function toDataURL(url: any, callback: any) {
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    const reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export default defineComponent({
  name: 'FitFlow',
  emits: ['completed'],
  setup(props, { emit }) {
    const { $gtag, $fb, $device } = useContext() as any
    const store = useStore() as any
    const token = computed(() => store.getters.token)
    const email = computed(() => store.getters['user/email'])
    const firstName = computed(() => store.getters['user/firstName'])

    const isDesktop = computed(() => $device.isDesktop)
    const requiredBrowser = computed(() => {
      if (!$device.isMobileOrTablet) {
        return ''
      }

      if ($device.isMacOS || $device.isIos) {
        return $device.isSafari ? '' : 'Safari'
      } else {
        return $device.isChrome ? '' : 'Chrome'
      }
    })

    const {
      stage,
      fittingState,
      updateFittingState,

      imageProcessing,
      isLoading,

      setUserParams,
      setImages,
      setUserPreference,
      getMeasurementData,
      registerMeasurements,
    } = useVirtualFitting()

    const errorMessage = computed(() => fittingState.errorMessage)

    const overlay = ref('')

    function setOverlay(name = '') {
      overlay.value = name
    }

    function retry() {
      setOverlay('')
      updateFittingState({ errorMessage: '', taskSetId: '' })
    }

    async function finishFitFlow() {
      setOverlay('algorithm')

      const person = await getMeasurementData()

      if (person) {
        $gtag.event('3dLook_widget_completed', { id: person.id })

        console.log('registering...', person)

        const measurement = await registerMeasurements()

        if (measurement) {
          console.log('registered:', measurement)

          store.commit('user/SET_USERDATA', { measurement })

          $gtag.event('virtual_fitting_complete', { email: email.value })
          $fb.query('trackCustom', 'FitFlow:complete', { email: email.value })
          $fb.track('CompleteRegistration', { content_name: 'completed fit flow' })

          console.log('...done!')

          emit('completed')
        }
      }
    }

    watch(
      () => imageProcessing.value,
      (next) => {
        next && setOverlay('images')
      },
    )

    watch(
      () => stage.value,
      (next) => {
        next === 6 && finishFitFlow()
      },
    )

    async function test() {
      // phase 2
      console.log('DEBUG STAGE: setUserParams..')
      // await setUserParams({ height: 290, weight: 60 })
      await setUserParams({ height: 290, weight: 60 })
      console.log('DEBUG STAGE: setUserParams => should have personId:', fittingState.personId)
      // await useDelay(1000)

      if (fittingState.personId) {
        // phase 3
        console.log('DEBUG STAGE: setImages..')
        const frontImage: any = await new Promise((resolve, reject) => toDataURL('/img/front.png', resolve))
        // const frontImage: any = await new Promise((resolve, reject) => toDataURL('/img/front-fake.jpeg', resolve))
        const sideImage: any = await new Promise((resolve, reject) => toDataURL('/img/side.png', resolve))
        // const sideImage: any = await new Promise((resolve, reject) => toDataURL('/img/side-fake.jpg', resolve))
        await setImages({ frontImage, sideImage })
        console.log('DEBUG STAGE: setImages => should have taskSetId:', fittingState.taskSetId)
        await useDelay(1000)

        if (fittingState.taskSetId) {
          // phase 4-5
          console.log('DEBUG STAGE: setUserPreference..')
          setUserPreference({ type: 'fitPreference', value: 'Normal' } as any)
          console.log('DEBUG STAGE: setUserPreference => should have fit:', fittingState.fitPreference)
          // await useDelay(1000)
          setUserPreference({ type: 'lengthPreference', value: 'BelowWidestPointOfHips' } as any)
          console.log('DEBUG STAGE: setUserPreference => should have length:', fittingState.lengthPreference)
          // await useDelay(30000)

          if (fittingState.fitPreference && fittingState.lengthPreference) {
            // phase 6
            console.log('DEBUG STAGE: getMeasurementData..')
            await getMeasurementData()
            console.log('DEBUG STAGE: getMeasurementData => should have some results?', fittingState)
          } else {
            console.log('BUG: no prefs')
          }
        } else {
          console.log('BUG: no taskSetId')
        }
      } else {
        console.log('BUG: no personId')
      }
    }

    async function testCamera() {
      console.log('DEBUG STAGE: setImages..')
      const frontImage: any = await new Promise((resolve, reject) => toDataURL('/img/front.png', resolve))
      const sideImage: any = await new Promise((resolve, reject) => toDataURL('/img/front.png', resolve))
      await setImages({ frontImage, sideImage })
      console.log('DEBUG STAGE: setImages => should have taskSetId:', fittingState.taskSetId)
      await useDelay(1000)
    }
    async function testCamera2() {
      console.log('DEBUG STAGE: setImages..')
      const frontImage: any = await new Promise((resolve, reject) => toDataURL('/img/front.png', resolve))
      const sideImage: any = await new Promise((resolve, reject) => toDataURL('/img/side.png', resolve))
      await setImages({ frontImage, sideImage })
      console.log('DEBUG STAGE: setImages => should have taskSetId:', fittingState.taskSetId)
      await useDelay(1000)
    }

    onMounted(() => {
      $fb.query('trackCustom', 'FitFlow:init', {})
    })

    return {
      // local
      overlay,
      setOverlay,
      retry,
      isDesktop,
      firstName,
      requiredBrowser,
      errorMessage,

      // useVirtualFitting
      isLoading,
      stage,
      imageProcessing,
      updateFittingState,
      setUserParams,
      setImages,
      setUserPreference,
      test,
      testCamera,
      testCamera2,
    }
  },
})
