












































import { useContext, defineComponent, useAsync, useMeta } from '@nuxtjs/composition-api'

import { usePageSettings } from '~/use/usePage'

import NeedHelpCta from '~/components/base/NeedHelpCta.vue'

export default defineComponent({
  components: { NeedHelpCta },
  setup() {
    const path = 'customer-service'
    const title = 'Customer Service'
    const { route, $content } = useContext() as any

    useMeta({
      title,
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })

    usePageSettings({
      navColors: {
        default: {
          textColor: 'white',
        },
      },
    })

    const getIndexContent = async () => {
      return await $content(path)
        .where({ slug: { $nin: ['home-try-on'] } })
        .only(['title', 'description', 'slug', 'order', 'link'])
        .sortBy('order', 'asc')
        .fetch()
    }
    const items = useAsync(() => getIndexContent())

    return {
      items,
      title,
    }
  },
  head: {},
})
