//
//
//
//

import gsap from 'gsap'

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: 0.5,
    },
    decimals: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      animatingValue: 0,
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.tween(oldValue, newValue)
    },
  },
  mounted() {
    this.tween(0, this.value)
  },
  methods: {
    tween(startValue, endValue) {
      const vm = this
      // function animate() {
      //   if (TWEEN.update()) {
      //     requestAnimationFrame(animate)
      //   }
      // }
      const tweenObject = { value: startValue }
      gsap.to(tweenObject, {
        value: endValue,
        duration: this.duration,
        onUpdate() {
          vm.animatingValue = tweenObject.value.toFixed(vm.decimals)
        },
      })
    },
  },
}
