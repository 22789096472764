






























































import { PropType, computed } from '@nuxtjs/composition-api'
import { ICollectionGridItem } from '~/services/api/db-types-shop'

import { PRODUCT_PARAMETERS, PRODUCT_COLORS } from '~/constants'

export default {
  name: 'ProductCard',
  components: {},
  props: {
    item: {
      type: Object as PropType<ICollectionGridItem>,
      required: true,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    filterParams: {
      type: Array,
      default: () => [], // exclude params
    },
  },
  setup(props: any) {
    const filter = props.filterParams as any
    const productParams = computed(() => {
      return PRODUCT_PARAMETERS.map((p) => ({ key: p.key, title: p.title })).filter(
        (p) => !filter.includes(p.key as string),
      )
    })
    return {
      productParams,
    }
  },
  computed: {
    productColors() {
      return PRODUCT_COLORS
    },
  },
}
