






















































import { defineComponent, ref, Ref, useContext, onMounted } from '@nuxtjs/composition-api'
import VFNextStepButton from '../VFNextStepButton.vue'
import { UserLengthPreference } from '~/services/api/db'

export default defineComponent({
  name: 'FitFlowLengthPreference',
  components: { VFNextStepButton },
  emits: ['done'],
  setup(props, { emit }) {
    const { $gtag } = useContext() as any
    const selected: Ref<UserLengthPreference | string> = ref('AboveWidestPointOfHips')

    function setSelected(value: UserLengthPreference) {
      selected.value = value
    }
    function emitDone() {
      emit('done', { type: 'lengthPreference', value: selected.value })
    }
    onMounted(() => {
      $gtag.event('fit_flow_5')
    })

    return {
      selected,
      setSelected,
      emitDone,
    }
  },
})
