





























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'NeedHelpCta',
  props: {
    mode: {
      type: String,
      default: 'page',
    },
    extra: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const classes = {
      btn: ['max-w-full'],
      wrapper: ['tracking-wide'],
    } as any

    switch (props.mode) {
      case 'sidebar':
        classes.wrapper.push('py-gutter-grid-2 bg-none text-left')
        classes.btn.push('w-250px')
        break
      case 'page':
      default:
        classes.wrapper.push('py-gutter-4 px-gutter-2 text-center')
        classes.btn.push('w-400px', 'mx-auto', 'mt-gutter-3', 'mb-gutter')
    }

    return {
      classes,
    }
  },
})
