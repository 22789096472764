import { render, staticRenderFns } from "./CartItem.vue?vue&type=template&id=687d8ff1&"
import script from "./CartItem.vue?vue&type=script&lang=ts&"
export * from "./CartItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default})
