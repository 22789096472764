






import { defineComponent, computed } from '@nuxtjs/composition-api'
export default defineComponent({
  name: 'PProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    isFake: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return props.isFake
        ? {}
        : {
            transform: `scale(${Math.min(1, props.progress)}, 1)`,
            transition: 'all ease-in-out 2s',
          }
    })

    const classes = computed(() => ({
      'progress-animation': !!props.isFake,
    }))

    return {
      styles,
      classes,
    }
  },
})
