





































































import { defineComponent, useStore, computed } from '@nuxtjs/composition-api'

import { useAuth } from '~/use/user/useAuth'

import CartBtn from '~/components/shop/cart/CartBtn.vue'
import NavDrawer from '~/components/nav/NavDrawer.vue'
import NavBtn from '~/components/nav/NavBtn.vue'

export default defineComponent({
  name: 'Navbar',
  components: { CartBtn, NavDrawer, NavBtn },
  setup() {
    const store = useStore() as any
    const isFitted = computed(() => store.getters['user/isFitted'])
    const isAuth = computed(() => !!store.getters.token)
    const { logoutUser } = useAuth()
    const colors = computed(() => store.getters['page/getColors'])
    const mode = computed(() => store.getters['page/getMode'])
    const scroll = computed(() => mode.value === 'scroll')
    const textColor = computed(() => colors.value.textColor)

    function showLoginOverlay() {
      store.commit('SET_OVERLAY', 'login')
    }
    return {
      mode,
      textColor,
      isAuth,
      isFitted,
      scroll,
      logoutUser,
      showLoginOverlay,
    }
  },
})
