










import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import PWrap from '~/components/base/PWrap'
export default defineComponent({
  name: 'StaticPageHeader',
  components: { PWrap },
  props: {},
  setup() {
    const classAnimationOnMounted = ref('p-move-up-full--initial')
    onMounted(() => (classAnimationOnMounted.value = 'p-move-up-full'))
    return { classAnimationOnMounted }
  },
})
