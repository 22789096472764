import { render, staticRenderFns } from "./FabricOverlay.vue?vue&type=template&id=4b9db686&"
import script from "./FabricOverlay.vue?vue&type=script&lang=ts&"
export * from "./FabricOverlay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Backdrop: require('/home/circleci/project/src/components/base/Backdrop.vue').default,IconsSvgTimesIcon: require('/home/circleci/project/src/components/icons/SvgTimesIcon.vue').default,PButtonIcon: require('/home/circleci/project/src/components/base/PButtonIcon.vue').default,FabricCard: require('/home/circleci/project/src/components/shop/FabricCard.vue').default,Overlay: require('/home/circleci/project/src/components/base/Overlay.vue').default})
