














































import { computed, defineComponent, ref, useStore } from '@nuxtjs/composition-api'

import { useCart } from '~/use/useCart'
import { useMessage } from '~/use/useMessage'

import { checkoutUpdateLineItems, checkoutRemoveLineItems } from '~/services/shopify/shopify-client'
import { ICartItem } from '~/services/api/db-types-shop'
// import { ShopifyCheckout, ShopifyCheckoutLineItem } from '~/services/types/shopify'

import CartItem from '~//components/shop/cart/CartItem.vue'
import AnimatedNumber from '~//components/shop/cart/AnimatedNumber.vue'

export default defineComponent({
  name: 'Cart',
  components: { CartItem, AnimatedNumber },
  props: {},
  setup() {
    const { notifyError } = useMessage()
    const store = useStore() as any
    const { totalQuantity, goToCheckout } = useCart()

    const checkout = computed<any>(() => store.state.cart.checkout)
    const items = computed<ICartItem[]>(() => store.getters['cart/getItems'])

    const isLoading = ref(false)

    const handleItemUpdate = async ({ id, quantity, variant }: any) => {
      try {
        isLoading.value = true

        const lineItem = { id, quantity, variantId: variant?.id }
        const checkoutId = checkout.value.id
        const result = await checkoutUpdateLineItems(checkoutId, [lineItem])
        store.commit('cart/SET_CHECKOUT', result)
      } catch (err) {
        notifyError(err)
      } finally {
        isLoading.value = false
      }
    }

    const handleItemRemove = async (item: ICartItem) => {
      try {
        isLoading.value = true

        const result = await checkoutRemoveLineItems(checkout.value.id, [item.id])

        store.commit('cart/SET_CHECKOUT', result)
      } catch (err) {
        notifyError(err)
      } finally {
        isLoading.value = false
      }
    }

    return {
      checkout,
      totalQuantity,
      items,
      isLoading,

      handleItemUpdate,
      handleItemRemove,
      goToCheckout,
    }
  },
})
