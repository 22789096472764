import { render, staticRenderFns } from "./orders.vue?vue&type=template&id=3ee51f7a&"
import script from "./orders.vue?vue&type=script&lang=js&"
export * from "./orders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShopPageTitle: require('/home/circleci/project/src/components/shop/ShopPageTitle.vue').default,ViewsOrdersHistory: require('/home/circleci/project/src/components/views/OrdersHistory.vue').default,NeedHelpCta: require('/home/circleci/project/src/components/base/NeedHelpCta.vue').default})
