






























import { ref, defineComponent, computed, onMounted, useStore } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'

export default defineComponent({
  name: 'CookieConsentOverlay',
  components: { Overlay },
  setup() {
    const isVisible = ref(false)
    const store = useStore() as any

    const cookieAccepted = computed<boolean>({
      get() {
        return store.getters.cookieAccepted
      },
      set(val: boolean) {
        store.commit('SET_STATE_PERSIST', { cookieAccepted: val })
      },
    })

    onMounted(() => {
      if (!cookieAccepted.value) {
        setTimeout(() => (isVisible.value = true), 3000)
      }
    })

    return { isVisible, cookieAccepted }
  },
})
