




















































import {
  defineComponent,
  ref,
  useContext,
  reactive,
  computed,
  watch,
  useStore,
  onMounted,
} from '@nuxtjs/composition-api'

import VFNextStepButton from '../VFNextStepButton.vue'
import { useDelay } from '~/use/useDelay'
import { useMessage } from '~/use/useMessage'
import { useLiveChat } from '~/use/useLiveChat'
import { useAuth, USER_ALREADY_EXISTS } from '~/use/user/useAuth'

import { getGraphQLErrorCode } from '~/utils/graphQLErrors'

function getFirstName(str: string) {
  const arr = str.trim().split(' ')
  if (arr.length === 1) {
    return arr[0]
  }
  return arr.slice(0, -1).join(' ').trim()
}

function getLastName(str: string, ifNone: '') {
  const arr = str.trim().split(' ')
  if (arr.length === 1) {
    return ifNone
  }
  return arr.slice(-1).join(' ')
}

export default defineComponent({
  name: 'FitFlowAuth',
  components: { VFNextStepButton },
  emits: ['done'],
  setup(props, { emit }) {
    const { openLiveChat } = useLiveChat()
    const { $gtag, $fb } = useContext() as any
    const store = useStore() as any

    const isLoading = ref(false)

    const fullName = ref('')
    const firstName = computed(() => getFirstName(fullName.value))
    const lastName = computed(() => getLastName(fullName.value, ''))

    const email = computed<string>({
      get() {
        return store.getters['user/email'] || ''
      },
      set(value: string) {
        store.commit('user/SET_USERDATA', { email: value })
      },
    })

    const { registerUser, requestMagicLink } = useAuth()
    const { showError } = useMessage()

    const isUserExists = ref(false)

    const canSubmit = computed(() => !!(email.value && fullName.value && !isUserExists.value))
    const isValidInput = computed(() => !Object.values(validationErrors).filter((v) => v).length)

    const validationErrors = reactive({
      name: '',
      email: '',
    })

    watch(
      () => email.value,
      () => (validationErrors.email = ''),
    )
    watch(
      () => fullName.value,
      () => (validationErrors.name = ''),
    )

    function validateInput() {
      validationErrors.name = lastName.value ? '' : 'Please enter your first & last name.'
      validationErrors.email = /\S+@\S+\.\S+/.test(email.value) ? '' : 'Please enter a valid email address.'
    }

    async function onRegisterUser() {
      validateInput()

      if (!isValidInput.value || isUserExists.value) {
        return
      }

      try {
        isLoading.value = true
        await registerUser({
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
        })
        $gtag.event('new_customer_email', { email: email.value })
        $fb.query('trackCustom', 'FitFlow:email', { email: email.value })

        emit('done')

        isLoading.value = false
      } catch (err) {
        onRegisterUserError(err)
      }
    }

    async function onRegisterUserError(err: any) {
      if (getGraphQLErrorCode(err.graphQLErrors) === USER_ALREADY_EXISTS) {
        try {
          await requestMagicLink({ email: email.value })
          validationErrors.email = 'An account using this email already exists.'
          await useDelay(1000)
          isUserExists.value = true
        } catch (err2) {
          showError({ err, text: 'Opps, something went wrong, please try again or contact our support', notify: true })
        } finally {
          isLoading.value = false
        }
      } else {
        showError({ err, notify: true })
        isLoading.value = false
      }
    }

    onMounted(() => {
      $gtag.event('fit_flow_1')
    })

    return {
      isLoading,
      canSubmit,

      fullName,
      firstName,
      lastName,
      email,

      validationErrors,
      isUserExists,

      onRegisterUser,
      openLiveChat,
    }
  },
})
