//
//
//
//
//
//
//
//
//

export default {
  name: 'SVGSideSplitLong',
}
