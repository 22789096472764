import { render, staticRenderFns } from "./CameraWidget.vue?vue&type=template&id=7113f5b0&"
import script from "./CameraWidget.vue?vue&type=script&lang=js&"
export * from "./CameraWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CameraHUD: require('/home/circleci/project/src/components/fit-flow/camera/CameraHUD.vue').default,Camera: require('/home/circleci/project/src/components/fit-flow/camera/Camera.vue').default})
