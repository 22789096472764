














import { defineComponent, onMounted, ref, useRouter } from '@nuxtjs/composition-api'
import { useEventListener } from '~/use/useEventListener'

const BACK_BTN_TYPE = ['top', 'back'] as const

export default defineComponent({
  name: 'BackBtn',
  setup() {
    const router = useRouter()
    const backBtnType = ref<typeof BACK_BTN_TYPE[number]>()

    useEventListener('scroll', onScroll)

    function onScroll() {
      const { scrollY } = window
      if (scrollY > 1000) {
        backBtnType.value = 'top'
      } else {
        backBtnType.value = 'back'
      }
    }

    const handleGoBack = () => {
      if (backBtnType.value === 'back') {
        router.go(-1)
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }

    onMounted(() => {
      onScroll()
    })

    return {
      handleGoBack,
      backBtnType,
    }
  },
})
