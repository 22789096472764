//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, defineComponent } from '@nuxtjs/composition-api'
import { useInfiniteSlider } from '~/use/useInfiniteSlider'

export default defineComponent({
  setup(props) {
    const proportionmans = [
      {
        image: '/new-site-images/4. proportionman/Other-model-fit01-short-03.jpg',
        name: 'Satoshi',
        fit: '01',
        size: 40,
      },
      {
        image: '/new-site-images/4. proportionman/Other-model-fit02-short-01.jpg',
        name: 'Brandon P.',
        fit: '02',
        size: 44,
      },
      {
        image: '/new-site-images/4. proportionman/Other-model-fit03-long-02.jpg',
        name: 'James P.',
        fit: '03',
        size: 42,
      },
      {
        image: '/new-site-images/4. proportionman/Other-model-fit03-short-03.jpg',
        name: 'Jonathan M.',
        fit: '03',
        size: 40,
      },
      {
        image: '/new-site-images/4. proportionman/Other-model-fit01-long-01.jpg',
        name: 'Caleb K.',
        fit: '01',
        size: 42,
      },
    ]

    const sliderRef = ref(null)
    const { id } = useInfiniteSlider(sliderRef)

    return {
      id,
      sliderRef,
      proportionmans,
    }
  },
})
