import { render, staticRenderFns } from "./fabrics.vue?vue&type=template&id=2d0c2cd6&scoped=true&"
import script from "./fabrics.vue?vue&type=script&lang=ts&"
export * from "./fabrics.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0c2cd6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageH1Title: require('/home/circleci/project/src/components/typography/PageH1Title.vue').default,PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,PageH3: require('/home/circleci/project/src/components/typography/PageH3.vue').default,TextPair: require('/home/circleci/project/src/components/typography/TextPair.vue').default,SVGFabricCircleText: require('/home/circleci/project/src/components/typography/SVGFabricCircleText.vue').default,NavCTAFitting: require('/home/circleci/project/src/components/nav/CTAFitting.vue').default,CTAMoreAbout: require('/home/circleci/project/src/components/base/CTAMoreAbout.vue').default})
