import { render, staticRenderFns } from "./CTAToast.vue?vue&type=template&id=6c081c98&"
import script from "./CTAToast.vue?vue&type=script&lang=ts&"
export * from "./CTAToast.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsSvgTimesIcon: require('/home/circleci/project/src/components/icons/SvgTimesIcon.vue').default})
