import { render, staticRenderFns } from "./StaticPage.vue?vue&type=template&id=4f6cb3bd&"
import script from "./StaticPage.vue?vue&type=script&lang=ts&"
export * from "./StaticPage.vue?vue&type=script&lang=ts&"
import style0 from "./StaticPage.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StaticPageHeader: require('/home/circleci/project/src/components/base/StaticPageHeader.vue').default,PButton: require('/home/circleci/project/src/components/base/PButton.vue').default,NeedHelpCta: require('/home/circleci/project/src/components/base/NeedHelpCta.vue').default,PIcon: require('/home/circleci/project/src/components/base/PIcon.tsx').default,PLink: require('/home/circleci/project/src/components/base/PLink.vue').default})
