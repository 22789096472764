






































import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CollectionPreviewCard',
  props: {
    item: {
      type: Object as any,
      required: true,
    },
  },
  setup(props: any) {
    return {}
  },
})
