




















import { ref, watch, reactive, defineComponent } from '@nuxtjs/composition-api'
import { useIntersectionObserver } from '~/use/useIntersectionObserver'

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: 'video/mp4',
    },
    stretch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { observe } = useIntersectionObserver()
    const pvideo = ref(null)
    const classes = ref('')
    const state = reactive({
      inview: false,
      loaded: false,
    })

    const hash = ref(Date.now())

    observe(pvideo, {
      rootMargin: '125% 0% 125% 0%',
      once: true,
      handler: () => {
        watch(
          () => props.src,
          (src) => {
            state.loaded = false
            if (src) {
              hash.value = Date.now()
            }
          },
          { immediate: true },
        )
      },
    })

    observe(pvideo, {
      once: true,
      handler: () => {
        state.inview = true
      },
    })

    const handleLoad = () => {
      state.loaded = true
    }

    let tries = 1
    const handleError = () => {
      classes.value = ''
      setTimeout(() => {
        if (tries < 4) {
          tries++
          hash.value = Date.now()
        }
      }, 3000)
    }

    watch(
      () => state,
      ({ inview, loaded }) => {
        if (inview && loaded) {
          classes.value = 'animated p-fade-in'
        } else {
          classes.value = 'p-fade-in--initial'
        }
      },
      { immediate: true, deep: true },
    )

    return {
      hash,
      pvideo,
      classes,
      handleLoad,
      handleError,
    }
  },
})
