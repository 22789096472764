//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  setup() {
    usePageSettings()
  },
})
