






export default {
  props: {
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
    path: {
      type: String,
      required: true,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
  },
}
