//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, toRefs, ref } from '@nuxtjs/composition-api'

import { useBtn } from '~/use/useBtn'

export default defineComponent({
  name: 'PButtonArrow',
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    to: {
      type: [Object, String],
      required: false,
      default: null,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  setup (props, context) {
    const { isTruthy, component, onClick, href } = useBtn(props, context)
    const { loading, rounded, primary, secondary, outlined, arrow } = toRefs(props)

    const computedClasses = computed(() => ({
    }))

    return {
      component,
      computedClasses,
      href,
      onClick,
    }
  },
})
