//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Loading',
  components: {},
  props: {},
  data: () => ({
    loading: false,
  }),
  methods: {
    start () {
      // console.log('loading start')
      this.loading = true
    },
    finish () {
      // console.log('loading finish')
      this.loading = false
    },
    fail (error) {
      console.log(error)
      // Optional	Called when a route couldn't be loaded (failed to fetch data for example).
    },
    increase (num) {
      // console.log(`loading... ${num}`)
    },
  },
})
