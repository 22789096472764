





























































































































































import { ref, defineComponent, useContext, onMounted, watch } from '@nuxtjs/composition-api'
import InstructionsSlider from '../InstructionsSlider.vue'
import SVGSpeakerIcon from '../SVGSpeakerIcon.vue'
import { useDelay } from '~/use/useDelay'
import { useRipple } from '~/use/useRipple'
import { getImagePathCDN } from '~/utils/shopify-helpers'
import SvgVideoPlayIcon from '~/components/icons/SvgVideoPlayIcon.vue'

export default defineComponent({
  name: 'FitFlowInstructions',
  components: { InstructionsSlider, SvgVideoPlayIcon, SVGSpeakerIcon },
  emits: ['done', 'schedule'],
  setup() {
    const { $gtag } = useContext() as any
    const page = ref(1)

    function goToPage(pageNumber: number) {
      page.value = pageNumber
    }

    const videInstructionFile = getImagePathCDN('/new-site-images/3. virtual fitting/video-instructions.mp4')

    function replayAudio() {
      const audioElement = document.getElementById('instruction-audio-player') as any

      if (audioElement) {
        try {
          audioElement.currentTime = 0
          audioElement.play()
        } catch (err) {
          console.log(err)
        }
      }
    }
    async function playVideo() {
      await useDelay(300)
      const videoElement = document.getElementById('instruction-video-player') as any

      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen()
        } else if (videoElement.webkitEnterFullScreen) {
          videoElement.webkitEnterFullScreen()
        }
        try {
          videoElement.play()
        } catch (err) {
          console.log(err)
        }
      }
    }
    function onSlideChange(number: number) {
      $gtag.event(`fit_flow_3_slide_${number}`)
    }

    watch(
      () => page.value,
      (next) => {
        if (next === 3) {
          $gtag.event('fit_flow_3_audio_1')
        } else if (next === 4) {
          $gtag.event('fit_flow_3_audio_2')
          replayAudio()
        }
      },
      { immediate: true },
    )

    onMounted(() => {})

    const rippleRef = ref(null)
    useRipple(rippleRef, 'currentColor')

    return {
      rippleRef,
      page,
      goToPage,
      replayAudio,
      playVideo,
      onSlideChange,
      videInstructionFile,
    }
  },
  data() {
    return {}
  },
  methods: {},
})
