//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PSwitchIcon',
  model: {
    prop: 'isEnabled',
    event: 'toggle',
  },
  props: {
    icons: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEnabled: Boolean,
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.isEnabled)
    },
  },
}
