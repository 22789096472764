




















import { defineComponent, useStore, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PersonalShopToolbar',
  setup() {
    const store = useStore() as any
    const fit = computed(() => store.getters['user/fit'])
    const size = computed(() => store.getters['user/size'])
    const firstName = computed(() => store.getters['user/firstName'])

    function showDetails() {
      store.commit('SET_OVERLAY', 'welcome')
    }
    return {
      fit,
      size,
      firstName,
      showDetails,
    }
  },
})
