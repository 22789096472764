


























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    to: {
      type: [String, Object] as any,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: 'MORE ABOUT PROPORTION.',
    },
  },
  setup() {},
})
