










































import { defineComponent } from '@nuxtjs/composition-api'

import ProductCard from '~/components/shop/product/ProductCard.vue'

export default defineComponent({
  name: 'BestSeller',
  components: { ProductCard },
  props: {
    product: {
      type: Object,
      reuqired: false,
      default: null,
    },
  },
  setup(props) {
    return {}
  },
})
