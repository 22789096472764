//
//
//
//
//
//
//
//
//

export default {
  name: 'SVGSideSplitShort',
}
