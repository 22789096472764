//
//
//
//
//
//
//
//
//

export default {
  name: 'SvgCtaArrowDown',
}
