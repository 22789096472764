






































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import debounce from 'lodash.debounce'
import { ICartItem } from '~/services/api/db-types-shop'

export const CartItemProps = {
  item: {
    type: Object as PropType<ICartItem>,
    default: undefined,
    required: true,
  },
  updateDebounce: {
    type: Number,
    default: 1000,
  },
}

export default defineComponent({
  props: CartItemProps,
  setup(props, { emit }) {
    const onQuantityUpdated = debounce(() => emit('update', props.item), props.updateDebounce)

    const colorAttributes = computed(() => props.item.customAttributes.find((attr: any) => attr.key === 'color'))
    return {
      colorAttributes,
      onQuantityUpdated,
    }
  },
})
