//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api'

import { useAuth } from '~/use/user/useAuth'
import { useMessage } from '~/use/useMessage'
import { useDelay } from '~/use/useDelay'

import ArrowIcon from '~/components/icons/SvgCtaArrowRight'

export default defineComponent({
  name: 'LoginForm',
  components: { ArrowIcon },
  setup(props, { emit }) {
    const email = ref('')
    const isLoading = ref(false)

    const { notifyError } = useMessage()
    const { requestMagicLink } = useAuth()

    const isValidInput = computed(() => /\S+@\S+\.\S+/.test(email.value))

    const errorMessage = ref('')
    const isDone = ref(false)

    watch(
      () => email.value,
      () => {
        isDone.value = false
        errorMessage.value = false
      },
    )

    const onLoginUser = async () => {
      emit('start')

      if (!isValidInput.value) {
        errorMessage.value = 'Please enter a valid email address:'
        return
      }

      try {
        isLoading.value = true
        await requestMagicLink({ email: email.value })
        isDone.value = true
        await useDelay(1000)
        emit('success')
      } catch (err) {
        errorMessage.value = 'No user exists with that email.'

        await useDelay(1000)
        emit('error')

        notifyError(err)
      } finally {
        isLoading.value = false
      }
    }

    return {
      email,
      onLoginUser,
      isLoading,
      isDone,
      errorMessage,
    }
  },
})
