//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { useAuth } from '~/use/user/useAuth.ts'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  name: 'Orders',
  setup() {
    const { navigationGuard } = useAuth()
    const isPageLoading = ref(true)
    usePageSettings()
    onMounted(() => {
      navigationGuard(() => (isPageLoading.value = false))
    })

    return {
      isPageLoading,
    }
  },
})
