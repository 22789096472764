












































import { defineComponent, ref, reactive, computed, useContext, onMounted } from '@nuxtjs/composition-api'

import { cmToFtIn, in2cm, ft2in, getHeightCm, lbToKg } from '../camera/utils'

import VFNextStepButton from '../VFNextStepButton.vue'

export default defineComponent({
  name: 'FitFlowWeightHeight',
  components: { VFNextStepButton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['done'],
  setup(props, { emit }) {
    const { $gtag, $device } = useContext() as any

    const isDevice = $device.isMobileOrTablet
    const height = reactive({
      ft: '',
      inch: '',
    })
    const weight = ref(null)

    const computedValues = computed(() => {
      const ft = parseInt(height.ft) || 0
      const inch = parseInt(height.inch) || 0
      const lb = weight.value
      return {
        height: getHeightCm(ft, inch) || 0,
        weight: lbToKg(lb) || 0,
      }
    })

    const isValidInput = computed(() => !!(computedValues.value.height * computedValues.value.weight))

    function onSubmit() {
      emit('done', { ...computedValues.value })
    }

    onMounted(() => {
      $gtag.event('fit_flow_2')
    })

    return {
      height,
      weight,
      onSubmit,
      isValidInput,
      isDevice,
    }
  },
})
