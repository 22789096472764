

































import { defineComponent, useStore, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'MeasurementsTable',
  props: {},
  setup() {
    const store = useStore() as any
    const measurementInfo = computed(() => store.getters['user/measurementInfo'])

    return {
      measurementInfo,
    }
  },
})
