//
//
//
//

export default {
  name: 'GradientDotIcon',
  props: {
    startColor: {
      type: String,
      default: '#31cf45',
    },
    endColor: {
      type: String,
      default: '#31cf45',
    },
  },
  computed: {
    styles() {
      return {
        background: `linear-gradient(to top right, ${this.startColor}, ${this.endColor})`,
      }
    },
  },
}
