













import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

import SvgChevronLeft from '~/components/icons/SvgChevronLeft.vue'
import SvgTimesIcon from '~/components/icons/SvgTimesIcon.vue'

export default defineComponent({
  name: 'FitFlowOverlay',
  components: { SvgChevronLeft, SvgTimesIcon },
  props: {
    canClose: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    return {}
  },
})
