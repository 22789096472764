import { render, staticRenderFns } from "./PSwitchIcon.vue?vue&type=template&id=85595676&"
import script from "./PSwitchIcon.vue?vue&type=script&lang=js&"
export * from "./PSwitchIcon.vue?vue&type=script&lang=js&"
import style0 from "./PSwitchIcon.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PIcon: require('/home/circleci/project/src/components/base/PIcon.tsx').default})
