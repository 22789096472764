import { render, staticRenderFns } from "./HowItWorks.vue?vue&type=template&id=851efb1a&"
import script from "./HowItWorks.vue?vue&type=script&lang=js&"
export * from "./HowItWorks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageH2: require('/home/circleci/project/src/components/typography/PageH2.vue').default,PageH3: require('/home/circleci/project/src/components/typography/PageH3.vue').default})
