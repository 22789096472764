













































































import {
  defineComponent,
  computed,
  reactive,
  Ref,
  watch,
  onMounted,
  useStore,
  useRoute,
  PropType,
} from '@nuxtjs/composition-api'

import { useDelay } from '~/use/useDelay'
import { useCart } from '~/use/useCart'
import { useMessage } from '~/use/useMessage'

import { IProductVariant, IProduct } from '~/services/api/db-types-shop'

const ACTION_BTN_STATE = {
  ENABLED: { disabled: false, loading: false },
  DISABLED: { disabled: true, loading: false },
  LOADING: { disabled: true, loading: true },
}

export default defineComponent({
  components: {},
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
      default: null,
    },
  },
  emits: [],
  setup(props, { emit }) {
    const { cartAddMany } = useCart()
    const store = useStore() as any
    const route = useRoute()
    const { showError, notifyError } = useMessage()

    const visible = computed({
      get() {
        return store.state.overlay === 'gift-card-buy'
      },
      set(overlay) {
        store.commit('SET_OVERLAY', overlay || '')
      },
    })

    const actionBtn = reactive({ ...ACTION_BTN_STATE.ENABLED })

    const variants: Ref<IProductVariant[]> = computed(() =>
      props.product.variants.filter((v: IProductVariant) => v.available),
    )
    const variantsSelected: Record<string, number> = reactive(
      variants.value.reduce((map: any, next: any) => {
        map[next.id] = 0
        return map
      }, {}),
    )

    const totalPrice: Ref<number> = computed(() => {
      let sum = 0
      for (const [variantId, qty] of Object.entries(variantsSelected)) {
        const variantPrice: any = variants.value.find((v: IProductVariant) => v.id === variantId)?.price
        const qtyPrice = String(variantPrice * qty)
        sum += parseInt(qtyPrice)
      }
      return sum
    })

    const totalQty: Ref<number> = computed(() => Object.values(variantsSelected).reduce((a, b) => a + b, 0))

    const purchase = computed(() => {
      const lineItems = []
      const entries = Object.entries(variantsSelected).filter(([id, qty]) => qty > 0)
      for (const [variantId, quantity] of entries) {
        const variant = variants.value.find((v: IProductVariant) => v.id === variantId)
        if (variant) {
          lineItems.push({
            customAttributes: variant.selectedOptions.map(({ name, value }) => ({ key: name, value })),
            quantity,
            variantId,
          })
        }
      }
      return lineItems
    })

    function updateQty(variantId: string, change: number) {
      const value: number = variantsSelected[variantId]
      variantsSelected[variantId] = Math.max(0, value + change)
    }

    async function handleActionBtnClick() {
      Object.assign(actionBtn, ACTION_BTN_STATE.LOADING)

      await cartAddMany(purchase.value, totalPrice.value)

      await useDelay(1500)
      visible.value = false
      await useDelay(500)

      showCartMessage(`ADDED ${purchase.value.length} ITEM${purchase.value.length > 1 ? 'S' : ''} TO CART`)
      Object.assign(actionBtn, ACTION_BTN_STATE.ENABLED)
    }

    function showCartMessage(message: string) {
      store.commit('cart/SHOW_MESSAGE', message)
      setTimeout(() => {
        store.commit('cart/SHOW_MESSAGE', '')
        store.commit('SET_OVERLAY', 'cart')
      }, 3000)
    }

    function getVariantSubtitle(index: number) {
      return [
        'The amount of our best selling crewneck short sleeve in his custom fit & size',
        'The amount of our double knit heavyweight crewneck long sleeve',
        'Two crewneck short sleeves in lightweight or mediumweight',
        'Now you’re giving him options!',
        'You know he needs this. (re)Build his wardrobe in his custom fit & size',
      ][index]
    }

    function selectVariant(variantId: string) {
      if (variantsSelected[variantId]) {
        updateQty(variantId, -1)
      } else {
        updateQty(variantId, 1)
      }
    }

    watch(
      () => totalPrice.value,
      (next: number) => Object.assign(actionBtn, ACTION_BTN_STATE[next ? 'ENABLED' : 'DISABLED']),
      { immediate: true },
    )

    onMounted(() => {
      if (variants.value[1]) {
        updateQty(variants.value[1].id, 1)
      }
    })

    return {
      visible,

      variants,
      variantsSelected,
      totalQty,
      totalPrice,

      actionBtn,
      handleActionBtnClick,
      updateQty,

      getVariantSubtitle,
      selectVariant,
    }
  },
})
