import { render, staticRenderFns } from "./fit-flow.vue?vue&type=template&id=4aaa9812&"
import script from "./fit-flow.vue?vue&type=script&lang=ts&"
export * from "./fit-flow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FitFlow: require('/home/circleci/project/src/components/fit-flow/FitFlow.vue').default})
