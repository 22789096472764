import { render, staticRenderFns } from "./gift-card.vue?vue&type=template&id=176c872e&"
import script from "./gift-card.vue?vue&type=script&lang=ts&"
export * from "./gift-card.vue?vue&type=script&lang=ts&"
import style0 from "./gift-card.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PButton: require('/home/circleci/project/src/components/base/PButton.vue').default,PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,GiftCardHelpOverlay: require('/home/circleci/project/src/components/shop/GiftCardHelpOverlay.vue').default})
