



















































import { defineComponent, computed, ref, watch, useStore } from '@nuxtjs/composition-api'

import Overlay from '~/components/base/Overlay.vue'
import LoginForm from '~/components/auth/LoginForm.vue'

export default defineComponent({
  name: 'AuthLoginOverlay',
  components: {
    Overlay,
    LoginForm,
  },
  setup() {
    const store = useStore() as any

    const _show = computed<boolean>({
      get() {
        return store.state.overlay === 'login'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'login' : '')
      },
    })

    const isAuth = computed(() => !!store.getters.token)

    const isFitSuggestMessage = ref(false)
    const isSuccessMessage = ref(false)

    function onLoginError() {
      isFitSuggestMessage.value = true
    }

    function onLogin() {
      isFitSuggestMessage.value = false
    }

    function onLoginSuccess() {
      isSuccessMessage.value = true
    }

    watch(
      () => _show.value,
      (next) => {
        if (!next) {
          isFitSuggestMessage.value = false
          isSuccessMessage.value = false
        }
      },
    )

    return {
      isAuth,
      _show,

      onLoginError,
      onLoginSuccess,
      onLogin,
      isSuccessMessage,
      isFitSuggestMessage,
    }
  },
})
