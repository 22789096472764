import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=659e1e14&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "659e1e14",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgLogo: require('/home/circleci/project/src/components/base/SvgLogo.vue').default,PLink: require('/home/circleci/project/src/components/base/PLink.vue').default})
