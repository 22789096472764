//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, reactive, watch, onMounted } from '@nuxtjs/composition-api'
import { useSwiper, options } from '~/use/useSwiper'
import { getImagePathCDN } from '~/utils/shopify-helpers'

export default defineComponent({
  name: 'InstructionsSlider',
  emits: ['change'],
  setup(props, { emit }) {
    const swiperRef = ref(null)
    const pages = [
      {
        image: getImagePathCDN('/new-site-images/3. virtual fitting/before_camera_slide_1.JPG'),
        title: 'A well lit space',
      },
      {
        image: getImagePathCDN('/new-site-images/3. virtual fitting/before_camera_slide_2.JPG'),
        title: 'A surface (counter or table) to place your phone roughly at waist height.',
      },
      {
        image: getImagePathCDN('/new-site-images/3. virtual fitting/before_camera_slide_3.JPG'),
        title: 'A coffee mug or other object to rest your phone vertically, screen facing you.',
      },
      {
        image: getImagePathCDN('/new-site-images/3. virtual fitting/before_camera_slide_4.JPG'),
        title: 'Enough space to step away from your phone so that your entire body is visible on the screen.',
      },
      {
        image: getImagePathCDN('/new-site-images/3. virtual fitting/before_camera_slide_5.JPG'),
        title: 'A decent fitting shirt. Baggy clothes will not work.',
      },
    ]
    const { activeIndex, swiper } = useSwiper(swiperRef, {
      ...options.default,
      slidesPerView: 1,
      navigation: false,
      spaceBetween: 15,
      pagination: {
        el: '#instructions_slider_pagination',
        type: 'bullets',
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className}"></span>`
        },
      },
      scrollbar: false,
    })

    watch(
      () => activeIndex.value,
      (next, prev) => {
        if (next !== prev && next >= 0) {
          emit('change', next + 1)
        }
      },
      { immediate: true },
    )

    onMounted(() => {})

    return {
      pages,
      swiperRef,
      activeIndex,
    }
  },
})
