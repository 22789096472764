








































import { defineComponent, computed, useStore, toRefs } from '@nuxtjs/composition-api'

import MeasurementsTable from '~/components/shop/MeasurementsTable.vue'

export default defineComponent({
  name: 'AccountDetails',
  components: { MeasurementsTable },
  setup() {
    const store = useStore() as any

    const { firstName, lastName, email } = toRefs(store.state.user)
    const fitPreference = computed(() => store.getters['user/fitPreference'])
    const lengthPreference = computed(() => store.getters['user/lengthPreference'])

    return {
      firstName,
      lastName,
      email,
      fitPreference,
      lengthPreference,
    }
  },
})
