//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, ref, onMounted, watch } from '@nuxtjs/composition-api'

import SVGGrade from './SVGComponents/SVGGrade.vue'
import SVGGradePointer from './SVGComponents/SVGGradePointer.vue'
import SVGBodyOutline from './SVGComponents/SVGBodyOutline.vue'
import SVGWarning from './SVGComponents/SVGWarning.vue'
import SvgChevronLeft from '~/components/icons/SvgChevronLeft.vue'
import SvgTimesIcon from '~/components/icons/SvgTimesIcon.vue'

export default defineComponent({
  name: 'CameraHUD',
  components: {
    SvgChevronLeft,
    SvgTimesIcon,

    SVGGrade,
    SVGGradePointer,
    SVGBodyOutline,
    SVGWarning,
  },
  props: {
    gyroscopePosition: {
      type: Number,
      default: 0,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    isTest: {
      type: Boolean,
      default: true,
    },
    timer: {
      type: Number,
      default: 0,
    },
  },
  emits: ['ready', 'close', 'pause'],
  setup(props, { emit }) {
    const step = ref(1)
    const instructionsVisible = ref(false)
    const requiresInteraction = ref(false)
    const gradeStyle = computed(() => ({ transform: `translate3d(0px, -${props.gyroscopePosition}px, 0px)` }))

    function _close() {
      emit('close')
    }
    function showInstructions(visible) {
      instructionsVisible.value = visible
      if (!props.isTest) {
        requiresInteraction.value = true
        emit('pause', true)
      }
    }
    function goBack() {
      if (!props.isTest || step.value === 1) {
        _close()
      } else {
        step.value--
      }
    }
    function goToStep(number) {
      step.value = number
      showInstructions(true)
    }
    function goLive() {
      emit('ready')
    }
    function confirmContinue() {
      requiresInteraction.value = false
      emit('pause', false)
    }
    function handleWarning(next, prev) {
      if (!props.isTest && next && !prev) {
        requiresInteraction.value = true
        emit('pause', true)
      }
    }

    watch(() => props.warning, handleWarning)

    onMounted(() => {
      goToStep(1)
    })

    return {
      gradeStyle,
      step,
      showInstructions,
      instructionsVisible,
      goBack,
      goToStep,
      goLive,
      confirmContinue,
      requiresInteraction,
    }
  },
})
