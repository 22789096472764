//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, reactive, useStore, useContext, ref, computed, onMounted } from '@nuxtjs/composition-api'

import Camera from './camera/Camera.vue'
import CameraHUD from './camera/CameraHUDSimple.vue'

export default defineComponent({
  name: 'CameraWidget',
  components: { Camera, CameraHUD },
  props: {},
  emits: ['images', 'close'],
  setup(props, { emit }) {
    const { $gtag, $device } = useContext()
    const isDevice = $device.isMobileOrTablet
    const store = useStore()

    const state = reactive({
      paused: false,
      type: null,
      hardValidation: { front: null, side: null },
      frontImage: null,
      sideImage: null,
      deviceCoordinates: {
        frontPhoto: {
          alphaZ: 0,
          betaX: 0,
          gammaY: 0,
        },
        sidePhoto: {
          alphaZ: 0,
          betaX: 0,
          gammaY: 0,
        },
      },
    })
    const setState = function (data) {
      Object.assign(state, data)
    }

    const setFrontPhoto = (file) => {
      setState({
        type: 'side',
        frontImage: file,
      })
    }
    const setSidePhoto = (file) => {
      setState({
        type: null,
        sideImage: file,
      })

      emit('images', {
        frontImage: state.frontImage,
        sideImage: state.sideImage,
      })
    }
    const disableTableFlow = () => {
      // Callback invoked whenever the gyroscope is disabled on the phone, disables the ability to take a photo of yourself.
      console.log('disableTableFlow')
      setState({
        type: null,
      })
      // window.location.href = '#/camera-mode-selection';
    }
    const turnOffCamera = () => {
      setState({
        type: null,
      })
    }

    const setDeviceCoordinates = (coords) => {
      // Callback on deviceorientation event. ({ betaX: 23.33, gammaY: 33.54, alphaZ: 65.32 })
      const type = `${state.type}Photo`
      state.deviceCoordinates[type] = coords
      console.log('setDeviceCoordinates', coords)
    }
    const toggleCamera = (type) => {
      setState({ type })
    }
    function restart() {
      turnOffCamera()
      // window.location.reload()
    }

    function startCameraWidget() {
      $gtag.event('fit_flow_3_camera_started')
      toggleCamera('front')
    }

    onMounted(() => {
      // startCameraWidget()
    })

    return {
      state,
      setFrontPhoto,
      setSidePhoto,
      disableTableFlow,
      turnOffCamera,
      setDeviceCoordinates,

      restart,

      // UI
      isDevice,
      startCameraWidget,
    }
  },
})
