






































































































import { VueTelInput } from 'vue-tel-input'
import { defineComponent, ref, onMounted, computed, useContext } from '@nuxtjs/composition-api'
import { useMessage } from '~/use/useMessage'
import { useAccount } from '~/use/user/useAccount'

import 'vue-tel-input/dist/vue-tel-input.css'

export default defineComponent({
  name: 'SMSReminder',
  components: {
    VueTelInput,
  },
  emits: ['done'],
  setup(props, { emit }) {
    const { $gtag } = useContext() as any
    const { showError } = useMessage()
    const { updateUser, setSMSReminder } = useAccount()
    const isReady = ref(false)

    const phoneNumber = ref('')
    const phoneNumberRaw = computed(() => `+${phoneNumber.value.replace(/\D/g, '')}`)
    const delayOptions = Array.from({ length: 24 }, (_, i) => i)
    const hrs = ref(1)
    const mins = ref(0)
    const minsFormat = computed(() => Number(mins.value).toString().padStart(2, '0'))
    const minsTotal = computed(() => hrs.value * 60 + mins.value)

    const canSetReminder = computed(() => !!(phoneNumber.value && minsTotal.value > 0))
    const isLoading = ref(false)
    const successMessage = ref('')

    function displayOption(option: number | string) {
      return `${option}:${minsFormat.value} ${mins.value > 0 ? 'min' : option === 1 ? 'hr' : 'hrs'}`
    }

    async function setReminder() {
      try {
        isLoading.value = true

        const date = new Date()
        date.setMinutes(date.getMinutes() + minsTotal.value)

        await Promise.all([updateUser({ phone: phoneNumber.value }), setSMSReminder(date.toISOString())])

        $gtag.event('sms_requested')

        const delayTimeStr = displayOption(hrs.value)

        successMessage.value = `You're all set. We'll send you a text in ${delayTimeStr}.`

        emit('done', delayTimeStr)
      } catch (err) {
        showError({ err, notify: true })
      } finally {
        isLoading.value = false
      }
    }

    function updateMins(modifier: number) {
      const pre = mins.value + modifier
      if (pre >= 60) {
        updateHrs(1)
        mins.value = 0
      } else if (pre < 0) {
        updateHrs(-1)
        mins.value = 45
      } else {
        mins.value = pre
      }
    }

    function updateHrs(modifier: number) {
      const pre = hrs.value + modifier
      if (pre > 23) {
        hrs.value = 0
      } else if (pre < 0) {
        hrs.value = 23
      } else {
        hrs.value = pre
      }
    }

    onMounted(() => {
      $gtag.event('fit_flow_sms_page')
      isReady.value = true
    })

    return {
      isReady,
      delayOptions,
      setReminder,
      updateHrs,
      updateMins,
      phoneNumber,
      hrs,
      displayOption,

      canSetReminder,
      isLoading,
      successMessage,
    }
  },
})
