//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ShopPageTitle',
}
