




























































































import { defineComponent, computed, PropType } from '@nuxtjs/composition-api'

import { IProduct } from '~/services/api/db-types-shop'
import { PRODUCT_COLORS, IProductOptionColorValue } from '~/constants'

export default defineComponent({
  name: 'ProductView',
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },
  setup(props) {
    const colorValues = props.product.optionMaps.color.values as IProductOptionColorValue[]
    const colors = computed(() => colorValues.map((v: IProductOptionColorValue) => PRODUCT_COLORS[v]))
    const images = computed(() => props.product.images.map((i) => i.src) || [])

    return {
      colors,
      images,
    }
  },
})
