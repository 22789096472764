//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EmailForm',
  props: {
    submitText: {
      type: String,
      default: 'Subscribe',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    successMessages: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      emailForm: {
        valid: false,
        errors: [],
      },
      email: null,
    }
  },
  computed: {
    errors() {
      return this.emailForm.errors.concat(this.errorMessages)
    },
  },
  watch: {},
  methods: {
    validate() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (emailRegex.test(this.email || '')) {
        this.$emit('submit', this.email)
      } else {
        this.emailForm.errors.push('Please provide a valid email address.')
      }
    },
    resetValidity() {
      this.emailForm.errors = []
    },
  },
}
