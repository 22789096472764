











































































































import { defineComponent, ref, useMeta, onMounted, onUnmounted, useRoute, computed } from '@nuxtjs/composition-api'
import { usePageSettings } from '~/use/usePage'

export default defineComponent({
  name: 'Production',
  setup() {
    const route = useRoute()

    const images = [
      '/v2/prod1.png',
      '/v2/prod2.jpeg',
      '/v2/prod3.png',
      '/v2/prod4.png',
      '/v2/prod5.jpeg',
      '/v2/prod6.jpeg',
      '/v2/prod6.png',
    ]

    useMeta({
      title: 'Our Production',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })
    usePageSettings()
    const activeIndex = ref(0)
    const nextIndex = computed(() => {
      return activeIndex.value + 1 >= images.length ? 0 : activeIndex.value + 1
    })
    const prevIndex = computed(() => {
      return activeIndex.value - 1 < 0 ? images.length - 1 : activeIndex.value - 1
    })

    let autoplayTimeout: any = null

    function prevSlide() {
      activeIndex.value = prevIndex.value
      autoplay()
    }

    function nextSlide() {
      activeIndex.value = nextIndex.value
      autoplay()
    }

    function goToSlide(index: number) {
      activeIndex.value = index
      autoplay()
    }

    function autoplay() {
      clearTimeout(autoplayTimeout)
      autoplayTimeout = setTimeout(nextSlide, 5000)
    }

    onMounted(() => {
      autoplay()
    })

    onUnmounted(() => {
      clearTimeout(autoplayTimeout)
    })

    return {
      images,
      activeIndex,
      prevIndex,
      nextIndex,
      nextSlide,
      prevSlide,
      goToSlide,
    }
  },
  head: {},
})
