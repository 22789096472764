//
//
//
//
//
//
//
//
//
//

let count = 0
export default {
  name: 'TextMarquee',
  props: {
    speed: {
      type: Number,
      default: 50,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    count++
    return {
      time: 0,
      name: 'marquee' + count,
      styleEl: null,
    }
  },
  watch: {},
  mounted() {
    this.start()
    this.styleEl = document.createElement('style')
  },
  methods: {
    getTime() {
      return Math.max(this.$el.firstChild.offsetWidth, this.$el.offsetWidth) / this.speed + 's'
    },
    prefix(key, value) {
      return ['-webkit-', '-moz-', '-ms-', ''].map((el) => `${el + key}:${value};`).join('')
    },
    keyframes() {
      const from = this.prefix('transform', `translateX(${this.$el.offsetWidth}px)`)
      const to = this.prefix('transform', `translateX(-${this.$el.firstChild.offsetWidth}px)`)
      const v = `@keyframes ${this.name} {
                from { ${from} }
                to { ${to} }
            }`
      this.styleEl.innerHTML = v
      document.head.appendChild(this.styleEl)
    },
    start() {
      this.$nextTick(() => {
        this.time = this.getTime()
        this.keyframes()
      })
    },
  },
}
