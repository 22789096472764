//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgChevronDown from '~/components/icons/SvgChevronDown.vue'
// TODO get content height in px for smooth animation (resize observer?)
export default {
  name: 'ExpandPanel',
  components: { SvgChevronDown },
  props: {
    autoHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    clickBar() {
      this.isOpen = !this.isOpen
    },
  },
}
