//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgFitCheckIcon from '~/components/icons/SvgFitCheckIcon.vue'
export default {
  title: 'FitSampleInfo',
  components: {
    SvgFitCheckIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fit: {
      type: [String, Number],
      default: '02',
    },
    size: {
      type: [String, Number, undefined],
      required: false,
      default: undefined,
    },
    loose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sizes: ['34', '36', '38', '40', '42', '44', '46', '48', '50'],
      fits: ['01', '02', '03'],
    }
  },
  methods: {
    selectedFit(value) {
      return value === String(this.fit)
    },
    selectedSize(value) {
      return value === String(this.size)
    },
  },
}
