import { render, staticRenderFns } from "./FabricCard.vue?vue&type=template&id=7e49b911&"
import script from "./FabricCard.vue?vue&type=script&lang=js&"
export * from "./FabricCard.vue?vue&type=script&lang=js&"
import style0 from "./FabricCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,TextPair: require('/home/circleci/project/src/components/typography/TextPair.vue').default})
