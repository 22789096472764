//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, useContext, useRoute, useRouter, ref } from '@nuxtjs/composition-api'

import { useMessage } from '~/use/useMessage'

export default defineComponent({
  layout: 'viewport',
  setup() {
    const { showError } = useMessage()
    const route = useRoute()
    const router = useRouter()
    const { $passwordProtect } = useContext()

    const password = ref('')
    const redirectPath = computed(() => {
      const { query } = route.value
      const path = query.previousPath
      return path || '/'
    })

    function onSubmit() {
      $passwordProtect.authorise(password.value)

      if ($passwordProtect.isAuthorised()) {
        router.push(redirectPath.value)
      } else {
        showError({ err: new Error('wrong password') })
      }
    }

    return {
      redirectPath,
      password,
      onSubmit,
    }
  },
  head: {
    meta: [
      {
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow, noarchive',
      },
    ],
  },
})
