










































import { defineComponent, onMounted, ref, Ref, useStore } from '@nuxtjs/composition-api'
import { useMessage } from '~/use/useMessage'
import { RootState } from '~/store'
import { getOrders } from '~/services/api/db-user'

export default defineComponent({
  setup() {
    const { showError } = useMessage()
    const store = useStore()
    const state = store.state as RootState
    const orders: Ref<any[]> = ref([
      // [123, '11/25/21', 'PAID', 'UNFULFILLED', 251.99],
      // [123, '11/25/21', 'PAID', 'UNFULFILLED', 251.99],
      // [123, '11/25/21', 'PAID', 'UNFULFILLED', 251.99],
    ])

    async function listOrders() {
      try {
        const results = await getOrders(state.token)

        orders.value = results.map((v) => {
          const createdAt = new Date(v.createdAt).toLocaleDateString()
          const amount = v.amount + ' USD'
          return [v.orderNumber, createdAt, v.paymentStatus, v.fulfillmentStatus, amount]
        })
      } catch (err) {
        showError({ err, notify: true })
      }
    }
    onMounted(async () => {
      await listOrders()
    })

    return {
      orders,
      listOrders,
    }
  },
})
