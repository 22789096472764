















































import { defineComponent } from '@nuxtjs/composition-api'

import NeedHelpCta from '~/components/base/NeedHelpCta.vue'
import StaticPageHeader from '~/components/base/StaticPageHeader.vue'

export default defineComponent({
  components: { NeedHelpCta, StaticPageHeader },
  props: {
    title: {
      type: String,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {}
  },
})
