//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, toRefs } from '@nuxtjs/composition-api'
import { useBtn } from '~/use/useBtn'
import ArrowIconRight from '~/components/icons/SvgCtaArrowRight'
import ArrowIconDown from '~/components/icons/SvgCtaArrowDown'
import LoadingDots from '~/components/base/LoadingDots.vue'

const optionalBoolean = {
  type: Boolean,
  default: false,
  required: false,
}
// const optionalString = {
//   type: String,
//   default: '',
//   required: false,
// }
export default defineComponent({
  name: 'PButtonCta',
  components: {
    ArrowIconRight,
    ArrowIconDown,
    LoadingDots,
  },
  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '50px',
    },
    primary: optionalBoolean,
    secondary: optionalBoolean,
    arrow: {
      type: String,
      default: 'right',
    },
    loading: optionalBoolean,
    shine: optionalBoolean,
  },
  setup(props, context) {
    const { isTruthy, component, onClick, href } = useBtn(props, context)
    const { primary, secondary, arrow } = toRefs(props)

    const computedClasses = computed(() => ({
      // color
      'text-white': isTruthy(primary.value),
      'text-black': isTruthy(secondary.value),
    }))

    const arrowComponent = arrow.value === 'right' ? 'ArrowIconRight' : 'ArrowIconDown'

    return {
      component,
      computedClasses,
      href,
      onClick,
      arrowComponent,
    }
  },
})
