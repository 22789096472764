




import {
  defineComponent,
  onMounted,
  ref,
  computed,
  useMeta,
  useContext,
  useRouter,
  useStore,
} from '@nuxtjs/composition-api'

import { useMessage } from '~/use/useMessage'
import { useAuth } from '~/use/user/useAuth'
// import { useDelay } from '~/use/useDelay'

export default defineComponent({
  name: 'GetFitted',
  components: { },
  layout: 'viewport',
  props: {},
  setup() {
    const { $gtag, $fb } = useContext() as any
    const { notifyError } = useMessage()
    const { fetchUser } = useAuth()
    const router = useRouter()
    const store = useStore() as any

    const token = computed(() => store.getters.token)
    const isFitted = computed(() => store.getters['user/isFitted'])
    const isReady = ref(false)
    const isPageLoading = ref(true)

    function goToShop() {
      router.push('/customer/shop')
    }

    onMounted(async () => {
      if (token.value) {
        await fetchUser() // refresh user
      }

      if (isFitted.value) {
        goToShop()
      } else {
        isReady.value = true
        isPageLoading.value = false
      }
    })

    useMeta({
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow, noarchive',
        },
      ],
    })

    return {
      isPageLoading,
      isReady,
      goToShop,
    }
  },
  head() {
    return {}
  },
})
