
















































































import { ref, defineComponent, onMounted } from '@nuxtjs/composition-api'

const QRCode = () => import(/* webpackChunkName: "dynamic-qr-code" */ '../QRCode.vue')
export default defineComponent({
  name: 'FitFlowInstructionsDesktop',
  components: { QRCode },
  emits: [],
  setup() {
    const page = ref(1)
    const isReady = ref(false)

    function goToPage(pageNumber: number) {
      page.value = pageNumber
    }
    onMounted(() => {
      isReady.value = true
    })

    return {
      page,
      goToPage,
      isReady,
    }
  },
})
