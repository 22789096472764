import { render, staticRenderFns } from "./CollectionCardPersonal.vue?vue&type=template&id=9f82bd3a&"
import script from "./CollectionCardPersonal.vue?vue&type=script&lang=ts&"
export * from "./CollectionCardPersonal.vue?vue&type=script&lang=ts&"
import style0 from "./CollectionCardPersonal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CollectionCardImages: require('/home/circleci/project/src/components/shop/collection/CollectionCardImages.vue').default,PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,CollectionOptionFabric: require('/home/circleci/project/src/components/shop/collection/OptionFabric.vue').default,CollectionOptions: require('/home/circleci/project/src/components/shop/collection/CollectionOptions.vue').default,CollectionOptionColor: require('/home/circleci/project/src/components/shop/collection/OptionColor.vue').default,PButton: require('/home/circleci/project/src/components/base/PButton.vue').default})
