//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'FabricCard',
  props: {
    fabricData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {},
})
