import { render, staticRenderFns } from "./account.vue?vue&type=template&id=df616aac&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShopPageTitle: require('/home/circleci/project/src/components/shop/ShopPageTitle.vue').default,PButtonIcon: require('/home/circleci/project/src/components/base/PButtonIcon.vue').default,ViewsAccountDetails: require('/home/circleci/project/src/components/views/AccountDetails.vue').default,NeedHelpCta: require('/home/circleci/project/src/components/base/NeedHelpCta.vue').default,WelcomeScreen: require('/home/circleci/project/src/components/shop/WelcomeScreen.vue').default})
