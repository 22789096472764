
















import { defineComponent, ref, watch, reactive, computed } from '@nuxtjs/composition-api'
import { getImagePathCDN } from '~/utils/shopify-helpers'
import { useIntersectionObserver } from '~/use/useIntersectionObserver'

export default defineComponent({
  name: 'PImgCDN',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    contain: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { observe } = useIntersectionObserver()

    const imgRef = ref(null)
    const imgClassBase = computed(() => (props.rel ? '' : 'absolute inset-0 h-full w-full'))
    const imgClass = ref('p-fade-in--initial')
    const imageState = reactive({
      inview: false,
      loaded: false,
    })
    const imageSrc = ref('')
    const hash = ref(`${Date.now()}-${props.src}`)

    function setImageSrc() {
      if (props.src.startsWith('http')) {
        imageSrc.value = props.src
      } else {
        imageSrc.value = getImagePathCDN(props.src)
      }
    }

    function onObserve() {
      imageState.inview = true
      setImageSrc()
    }

    observe(imgRef, {
      rootMargin: '125% 0% 125% 0%',
      once: true,
      threshold: 0.5,
      handler: () => {
        onObserve()
      },
    })

    watch(
      () => imageState,
      ({ inview, loaded }) => {
        if (inview && loaded) {
          imgClass.value = 'animated p-fade-in'
        }
      },
      { immediate: true, deep: true },
    )

    const handleLoad = () => {
      imageState.loaded = true
    }

    const handleError = () => {}

    return {
      imgRef,
      imageSrc,
      hash,
      imgClassBase,
      imgClass,
      handleLoad,
      handleError,
    }
  },
})
