
















































































import { defineComponent, computed, reactive, Ref, watch, PropType, useStore } from '@nuxtjs/composition-api'

import { useDelay } from '~/use/useDelay'
import { useCart } from '~/use/useCart'

import { IProductVariant, IProductOptionSelected, IProduct } from '~/services/api/db-types-shop'
import { getProductTitle } from '~/utils/collectionHelpers'

import Overlay from '~/components/base/Overlay.vue'
import SvgTimesIcon from '~/components/icons/SvgTimesIcon.vue'

const ACTION_BTN_STATE = {
  ENABLED: { disabled: false, loading: false },
  DISABLED: { disabled: true, loading: false },
  LOADING: { disabled: true, loading: true },
}

export default defineComponent({
  name: 'PurchaseOverlay',
  components: { Overlay, SvgTimesIcon },
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
      default: null,
    },
  },
  emits: ['addItem'],
  setup(props, { emit }) {
    const { cartAddMany } = useCart()
    const store = useStore() as any
    const fit = computed(() => store.getters['user/fit'])
    const size = computed(() => store.getters['user/size'])

    const productTitle = computed(() => getProductTitle(props.product))

    const actionBtn = reactive({ ...ACTION_BTN_STATE.ENABLED })
    const isPurchaseVisible = computed<boolean>({
      get() {
        return store.state.overlay === 'purchase'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'purchase' : '')
      },
    })

    const variants: Ref<IProductVariant[]> = computed(() =>
      props.product.variants.filter((v: IProductVariant) => {
        const vSize = (v.selectedOptions || []).find((o: IProductOptionSelected) => o.name === 'size')
        return v.available && vSize?.value === String(size.value || '40')
      }),
    )
    const variantsSelected: Record<string, number> = reactive(
      variants.value.reduce((map: any, next: any) => {
        map[next.id] = 0
        return map
      }, {}),
    )

    const totalPrice: Ref<number> = computed(() => {
      let sum = 0
      for (const [variantId, qty] of Object.entries(variantsSelected)) {
        const variantPrice: any = variants.value.find((v: IProductVariant) => v.id === variantId)?.price
        const qtyPrice = String(variantPrice * qty)
        sum += parseInt(qtyPrice)
      }
      return sum
    })
    const totalQty: Ref<number> = computed(() => Object.values(variantsSelected).reduce((a, b) => a + b, 0))

    const purchase = computed(() => {
      const lineItems = []
      const entries = Object.entries(variantsSelected).filter(([id, qty]) => qty > 0)
      for (const [variantId, quantity] of entries) {
        const variant = variants.value.find((v: IProductVariant) => v.id === variantId)
        if (variant) {
          lineItems.push({
            customAttributes: variant.selectedOptions.map(({ name, value }) => ({ key: name, value })),
            quantity,
            variantId,
            // variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjI2MjU5NjY4OTk1Ng==',
            // result: btoa("gid://shopify/ProductVariant/32262596689956") => 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjI2MjU5NjY4OTk1Ng==
            // original variant: https://proportion-development.myshopify.com/admin/products/4811344576548/variants/32262596689956
          })
        }
      }
      return lineItems
    })

    function updateQty(variantId: string, change: number) {
      const value: number = variantsSelected[variantId]
      variantsSelected[variantId] = Math.max(0, value + change)
    }

    async function handleActionBtnClick() {
      Object.assign(actionBtn, ACTION_BTN_STATE.LOADING)
      await cartAddMany(purchase.value, totalPrice.value)
      await useDelay(1500)
      store.commit('SET_OVERLAY', '')
      await useDelay(500)
      emit('addItem', `ADDED ${purchase.value.length} ITEM${purchase.value.length > 1 ? 'S' : ''} TO CART`)
      Object.assign(actionBtn, ACTION_BTN_STATE.ENABLED)
    }

    function getVariantTitle(variant: IProductVariant) {
      return variant.selectedOptions?.find((o: IProductOptionSelected) => o.name === 'color')?.value
    }

    watch(
      () => totalPrice.value,
      (next: number) => Object.assign(actionBtn, ACTION_BTN_STATE[next ? 'ENABLED' : 'DISABLED']),
      { immediate: true },
    )

    return {
      isPurchaseVisible,

      productTitle,

      fit,
      size,
      variants,
      variantsSelected,

      totalQty,
      totalPrice,

      actionBtn,
      handleActionBtnClick,

      updateQty,
      getVariantTitle,
    }
  },
})
