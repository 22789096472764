//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PProgressBar from '~/components/base/PProgressBar.vue'
import SvgTimesIcon from '~/components/icons/SvgTimesIcon.vue'

export default {
  name: 'FitFlowProgressBar',
  components: { PProgressBar, SvgTimesIcon },
  props: {
    stage: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 6,
    },
    name: {
      type: String,
      default: '',
    },
  },
}
