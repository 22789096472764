var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-toolbar",attrs:{"id":"sms-reminder"}},[_vm._m(0),_vm._v(" "),_c('section',[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"px-gutter-2"},[_c('div',{staticClass:"flex items-center justify-between pb-4"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"grid grid-flow-col gap-gutter-1",staticStyle:{"height":"60px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.hrs),expression:"hrs"}],staticClass:"bg-white rounded-full appearance-none outline-none w-80px px-gutter-2 box-content text-center select-none",staticStyle:{"text-align-last":"center"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.hrs=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.delayOptions),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v("\n              "+_vm._s(_vm.displayOption(option))+"\n            ")])}),0),_vm._v(" "),_c('div',{staticClass:"grid gap-px",staticStyle:{"width":"37px"}},[_c('div',{staticClass:"bg-white rounded-t-3xl flex items-center justify-center",on:{"click":function($event){return _vm.updateMins(15)}}},[_c('IconsSvgChevronUp',{attrs:{"width":"14px"}})],1),_vm._v(" "),_c('div',{staticClass:"bg-white rounded-b-3xl flex items-center justify-center",on:{"click":function($event){return _vm.updateMins(-15)}}},[_c('IconsSvgChevronDown',{attrs:{"width":"14px"}})],1)])])]),_vm._v(" "),_c('div',{},[(_vm.isReady)?_c('VueTelInput',{attrs:{"input-options":{
            placeholder: '+1 ( ___ )  ___ - ____',
            maxlength: 24,
            required: true,
            type: 'tel',
            autocomplete: 'on',
            autofocus: true,
            name: 'phone',
            styleClasses: {
              'p-tel-input__input': true,
              'text-center': true,
            },
          },"auto-format":true,"dropdown-options":{
            showFlags: true,
            showDialCodeInList: true,
          },"only-countries":['US'],"style-classes":['p-tel-input'],"default-country":"US","auto-default-country":false,"mode":"international"},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('IconsSvgChevronDown',{attrs:{"width":"14px"}})]},proxy:true}],null,false,2368871507),model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}):_vm._e()],1)])]),_vm._v(" "),_c('footer',[_c('PButton',{staticClass:"uppercase tracking-wider bg-gray-600",attrs:{"primary":"","arrow":false,"width":"300px","height":"50px","disabled":!_vm.canSetReminder,"loading":_vm.isLoading},on:{"click":function($event){return _vm.setReminder()}}},[_vm._v("\n      SCHEDULE\n    ")])],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.successMessage)?_c('div',{staticClass:"absolute inset-0 bg-gray-100 z-1"},[_c('div',{staticClass:"w-full h-full flex items-center"},[_c('div',{staticClass:"w-full text-center p-gutter-2"},[_c('h3',{staticClass:"text-4xl"},[_vm._v("\n            "+_vm._s(_vm.successMessage)+"\n          ")])])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('h4',{staticClass:"text-h3 lg:text-h2 text-center font-serif"},[_vm._v("SMS Reminder")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-gutter-2 leading-loose text-center text-small"},[_c('p',[_vm._v("\n        Let us know when you'll be in a good spot to take your photos and complete your virtual fitting, and we will\n        send you a reminder text.\n      ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-small"},[_c('span',[_vm._v("Send me a reminder in")])])}]

export { render, staticRenderFns }