
































import { defineComponent, computed, ref, useStore } from '@nuxtjs/composition-api'

import { useDelay } from '~/use/useDelay'
import { useCart } from '~/use/useCart'

export default defineComponent({
  name: 'CartMessage',
  setup() {
    const store = useStore() as any
    const { totalQuantity, goToCheckout } = useCart()
    const cartMessage = computed(() => store.state.cart.cartMessage)
    const checkout = computed<any>(() => store.state.cart.checkout)
    const isLoading = ref(false)

    function showCart() {
      store.commit('SET_OVERLAY', 'cart')
    }
    return {
      cartMessage,
      checkout,
      isLoading,
      totalQuantity,
      showCart,
      goToCheckout,
    }
  },
})
