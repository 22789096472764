

































































































































import { defineComponent, useStore, computed } from '@nuxtjs/composition-api'

import { useAuth } from '~/use/user/useAuth'

import FooterGridItem from '~/components/nav/FooterGridItem.vue'

export default defineComponent({
  name: 'Footer',
  components: { FooterGridItem },
  setup() {
    const store = useStore() as any
    const isFitted = computed(() => store.getters['user/isFitted'])

    const { logoutUser } = useAuth()

    function showLoginOverlay() {
      store.commit('SET_OVERLAY', 'login')
    }

    return {
      isFitted,
      logoutUser,
      showLoginOverlay,
    }
  },
})
