


















export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {}
  },
}
