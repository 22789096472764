import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2e701821&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthLoginOverlay: require('/home/circleci/project/src/components/auth/LoginOverlay.vue').default,CookieConsentOverlay: require('/home/circleci/project/src/components/base/CookieConsentOverlay.vue').default,CartOverlay: require('/home/circleci/project/src/components/shop/cart/CartOverlay.vue').default,NavNavbar: require('/home/circleci/project/src/components/nav/Navbar.vue').default})
