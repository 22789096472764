import { render, staticRenderFns } from "./FeaturedPages.vue?vue&type=template&id=06fdddcc&"
import script from "./FeaturedPages.vue?vue&type=script&lang=js&"
export * from "./FeaturedPages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageH2: require('/home/circleci/project/src/components/typography/PageH2.vue').default,PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,PButtonArrow: require('/home/circleci/project/src/components/base/PButtonArrow.vue').default})
