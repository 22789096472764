








































import { defineComponent, useAsync, useContext, useMeta } from '@nuxtjs/composition-api'

import { usePageSettings } from '~/use/usePage'

import NeedHelpCta from '~/components/base/NeedHelpCta.vue'

export default defineComponent({
  components: { NeedHelpCta },
  setup() {
    const path = 'legal'
    const title = 'Legal'
    const { route, $content } = useContext() as any
    usePageSettings({
      navColors: {
        default: {
          textColor: 'white',
        },
      },
    })
    useMeta({
      title: 'Legal',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })

    const getIndexContent = async () => {
      return await $content(path)
        .only(['title', 'description', 'slug'])
        // .sortBy('createdAt', 'desc')
        .fetch()
    }
    const items = useAsync(() => getIndexContent())

    return {
      items,
      title,
    }
  },
  head: {},
})
