







































import { defineComponent, onMounted, computed, watch, ref, useRoute } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PersonalShopToolbarNav',
  setup() {
    const route = useRoute()

    const windowHash = ref('')

    function updateHash(hash: string) {
      windowHash.value = hash
    }

    onMounted(() => {
      watch(() => route.value.hash, updateHash, { immediate: true })
    })

    return {
      windowHash,
    }
  },
})
