//
//
//
//
//
//

export default {
  name: 'LoadingDots',
}
