//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, reactive, computed, onMounted, onUnmounted, watch } from '@nuxtjs/composition-api'

import { getOrientation, fixOrientation, isSamsungBrowser, imgToBase64, getChromeVersion } from './utils'

// System
const audioTimer = '/audio/timer-new.mp3'
const audioPhotoShutter = '/audio/shutter-sound-new.mp3'

// Front and side
const audioStandYourPhone = '/audio/1.1.mp3'
const audioSuccessGyro = '/audio/1.2.mp3'

// Front
const audioFirstInstructionF = '/audio/1.3.mp3'
const audioSecondInstructionF = '/audio/1.4.mp3'
const audioThirdInstructionF = '/audio/1.5.mp3'
const audioFourthInstructionF = '/audio/1.6.mp3'
const audioSuccessPhotoF = '/audio/1.7.mp3'

// Side
const audioFirstInstructionS = '/audio/2.1.mp3'
const audioSecondInstructionS = '/audio/2.2.mp3'
const audioThirdInstructionS = '/audio/2.3.mp3'
const audioSuccessPhotoS = '/audio/2.4.mp3'

// Hard-validation front and side
const audioStandYourPhoneHVFS = '/audio/3.1.mp3'
const audioSuccessGyroHVFS = '/audio/3.2.mp3'

// Hard-validation front and side - FRONT
const audioFirstInstructionHVFSF = '/audio/3.3.mp3'
const audioSecondInstructionHVFSF = '/audio/3.4.mp3'
const audioThirdInstructionHVFSF = '/audio/3.5.mp3'
const audioSuccessPhotoHVFSF = '/audio/3.6.mp3'

// Hard-validation front and side - SIDE
const audioFirstInstructionHVFSS = '/audio/4.1.mp3'
const audioSecondInstructionHVFSS = '/audio/4.2.mp3'
const audioThirdInstructionHVFSS = '/audio/4.3.mp3'
const audioSuccessPhotoHVFSS = '/audio/4.4.mp3'

// Hard-validation front
const audioStandYourPhoneHVF = '/audio/5.1.mp3'
const audioSuccessGyroHVF = '/audio/5.2.mp3'
const audioFirstInstructionHVF = '/audio/5.3.mp3'
const audioSecondInstructionHVF = '/audio/5.4.mp3'
const audioThirdInstructionHVF = '/audio/5.5.mp3'
const audioFourthInstructionHVF = '/audio/5.6.mp3'
const audioSuccessPhotoHVF = '/audio/5.7.mp3'

// Hard-validation front and side
const audioStandYourPhoneHVS = '/audio/6.1.mp3'
const audioSuccessGyroHVS = '/audio/6.2.mp3'
const audioFirstInstructionHVS = '/audio/6.3.mp3'
const audioSecondInstructionHVS = '/audio/6.4.mp3'
const audioThirdInstructionHVS = '/audio/6.5.mp3'
const audioFourthInstructionHVS = '/audio/6.6.mp3'
const audioSuccessPhotoHVS = '/audio/6.7.mp3'

const AUIDO_CASES = {
  standPhone: [audioStandYourPhone, audioStandYourPhoneHVFS, audioStandYourPhoneHVF, audioStandYourPhoneHVS],
  successGyro: [audioSuccessGyro, audioSuccessGyroHVFS, audioSuccessGyroHVF, audioSuccessGyroHVS],
  firstInstruction: [
    audioFirstInstructionF,
    audioFirstInstructionS,
    audioFirstInstructionHVFSF,
    audioFirstInstructionHVFSS,
    audioFirstInstructionHVF,
    audioFirstInstructionHVS,
  ],
  secondInstruction: [
    audioSecondInstructionF,
    audioSecondInstructionS,
    audioSecondInstructionHVFSF,
    audioSecondInstructionHVFSS,
    audioSecondInstructionHVF,
    audioSecondInstructionHVS,
  ],
  thirdInstruction: [
    audioThirdInstructionF,
    audioThirdInstructionS,
    audioThirdInstructionHVFSF,
    audioThirdInstructionHVFSS,
    audioThirdInstructionHVF,
    audioThirdInstructionHVS,
  ],
  fourthInstruction: [audioFourthInstructionF, null, null, null, audioFourthInstructionHVF, audioFourthInstructionHVS],
  finallySuccess: [audioSuccessPhotoS, audioSuccessPhotoHVFSS, audioSuccessPhotoHVF, audioSuccessPhotoHVS],
}

function isPlatform(platform) {
  const ua = navigator.userAgent

  if (platform === 'iOS') {
    return ua.includes('iPhone') || ua.includes('Mac OS')
  }

  if (platform === 'Android') {
    return ua.includes('Android') || ua.includes('Linux')
  }

  return false
}

export default defineComponent({
  name: 'Camera',
  components: {},
  props: {
    type: {
      type: String,
      default: '',
    },
    hardValidation: {
      type: Object,
      default: () => ({
        front: null,
        side: null,
      }),
    },
    isTableFlow: {
      type: Boolean,
      default: true,
    },
    paused: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setDeviceCoordinates', 'disableTableFlow', 'turnOffCamera', 'saveSide', 'saveFront'],
  setup(props, { emit }) {
    const PLAYSPEED = 1
    const state = reactive({
      imgURI: null,
      processing: false,
      warning: false,
      isCameraAccess: true,
      camerasArr: [],
      activeCamera: -1,
      gyroscopePosition: 180,
      tapScreen: props.isTableFlow,

      activeAudioTrack: audioStandYourPhone,
      activeAudioTrackIndex: 0,

      isGyroTimerAccess: false,
      photoTimerSecs: 4,
      isPhotoTimer: false,
      isFirstAudio: true,
      isLastPhoto: false,
      gyroTimer: null,
      photoTimer: null,
      debug: true,
    })
    const $videoRef = ref(null)
    const $audioRef = ref(null)

    let rotX = 0
    let rotY = 0
    let stream = null

    const gyroObject = reactive({
      alpha: 0,
      beta: 0,
      gamma: 0,
    })

    function updateGyroObject(eventData) {
      Object.assign(gyroObject, eventData)
    }

    const validation = reactive({
      hardValidationFS: false,
      hardValidationF: false,
      hardValidationS: false,
      isHardValidation: false,
    })

    if (props.hardValidation) {
      // in dev mode comment 3 lines below
      validation.hardValidationFS = props.hardValidation.front && props.hardValidation.side
      validation.hardValidationF = props.hardValidation.front && !props.hardValidation.side
      validation.hardValidationS = !props.hardValidation.front && props.hardValidation.side
      validation.isHardValidation =
        validation.hardValidationFS || validation.hardValidationF || validation.hardValidationS
    }

    const cameraType = props.isTableFlow ? 'user' : 'environment'
    const VIDEO_CONFIG = {
      audio: false,
      video: {
        facingMode: cameraType,
        width: { exact: 1280 },
      },
    }

    function setState(data) {
      Object.assign(state, data)
    }

    async function startCamera(config, callback) {
      try {
        stream = await navigator.mediaDevices.getUserMedia(config)
        $videoRef.value.srcObject = stream

        if (callback) {
          callback()
        }
      } catch (error) {
        if (isPlatform('Android')) {
          const cameras = await additionalCamerasCheck()
          const isAvailableCameras = await camerasFilter(cameras)

          if (isAvailableCameras) {
            return
          }
        }

        setState({ isCameraAccess: false })

        if ($audioRef.value) {
          $audioRef.value.pause()
        }
      }
    }

    function startStream() {
      startCamera(VIDEO_CONFIG, getUserDevices)
    }

    // TODO consider move to utils
    function getUserDevices() {
      return navigator.mediaDevices.enumerateDevices().then(async (devices) => {
        const devicesArr = []
        const isTableFlow = props.isTableFlow

        const cameraType = isTableFlow ? 'front' : 'back'

        devices.forEach((e) => {
          if (e.kind === 'videoinput' && e.label.includes(cameraType)) {
            devicesArr.push(e.deviceId)
          }
        })

        // for android (start stream from camera by id)
        if (isPlatform('Android')) {
          androidCameraStart(devicesArr)

          return Promise.resolve()
        }

        // for other (start stream from default camera)
        if (devicesArr.length > 1) {
          setState({
            camerasArr: devicesArr,
          })
        }
      })
    }
    function emitDeviceCoordinates() {
      const { beta: betaX, gamma: gammaY, alpha: alphaZ } = gyroObject

      emit('setDeviceCoordinates', { alphaZ, betaX, gammaY })
    }

    // ======= ANDROID stuff
    function additionalCamerasCheck() {
      return navigator.mediaDevices.enumerateDevices().then(async (devices) => {
        const devicesArr = []

        devices.forEach((e, i) => {
          if (e.kind === 'videoinput' && e.label.includes('back')) {
            devicesArr.push(e.deviceId)
          }
        })

        return devicesArr
      })
    }
    async function androidCameraStart(cameras) {
      setState({
        camerasArr: cameras,
        activeCamera: 0,
      })

      const videoConfig = {
        video: {
          deviceId: cameras[0],
          width: { exact: 1280 },
          facingMode: cameraType,
        },
        audio: false,
      }

      await stream.getTracks().forEach((track) => {
        track.stop()
        track.enabled = false
      })

      console.log('androidCameraStart')

      startCamera(videoConfig)
    }
    async function changeCamera(id) {
      const { camerasArr } = state

      const videoConfig = {
        video: {
          deviceId: camerasArr[id],
          width: { exact: 1280 },
        },
        audio: false,
      }

      await stream.getTracks().forEach((track) => track.stop())

      setState({
        activeCamera: id,
      })

      startCamera(videoConfig)
    }
    async function camerasFilter(camerasArr) {
      const filteredCameras = []
      let isCameraAllowed = false

      // check for case if the camera is unavailable
      for (let i = 0; i < camerasArr.length; i++) {
        const videoConfig = {
          video: {
            deviceId: camerasArr[i],
            width: { exact: 1280 },
          },
          audio: false,
        }

        try {
          // eslint-disable-next-line no-await-in-loop
          stream = await navigator.mediaDevices.getUserMedia(videoConfig)

          filteredCameras.push(camerasArr[i])

          isCameraAllowed = true
        } catch (error) {
          console.error(error)
        }
      }

      if (isCameraAllowed) {
        setState({
          camerasArr: filteredCameras,
          activeCamera: 0,
        })

        stream.getTracks().forEach((track) => {
          track.stop()
          track.enabled = false
        })

        const videoConfig = {
          video: {
            deviceId: filteredCameras[0],
            width: { exact: 1280 },
          },
          audio: false,
        }

        console.log('camerasFilter')

        startCamera(videoConfig)

        return true
      }

      return false
    }

    // ===================
    function takePhoto() {
      const { isTableFlow } = props
      const callback = isTableFlow ? setPhotoTableFlow : setPhoto

      try {
        const settings = stream.getVideoTracks()[0].getSettings()
        const canvas = document.createElement('canvas')
        // kostil incoming
        if (isSamsungBrowser()) {
          canvas.width = settings.height
          canvas.height = settings.width
        } else {
          canvas.width = settings.width
          canvas.height = settings.height
        }

        canvas.getContext('2d').drawImage($videoRef.value, 0, 0, canvas.width, canvas.height)
        emitDeviceCoordinates()

        setState({ processing: true })

        canvas.toBlob(callback)
      } catch (exception) {
        alert('Problems with photo. Please try again.')
        window.location.reload()
      }
    }
    async function setPhoto(blob) {
      const { type } = props

      try {
        let image

        // for chrome >= 81 we dont need to use fixOrientation
        if (isPlatform('Android') && getChromeVersion() >= 81) {
          image = await imgToBase64(blob)
        } else {
          image = await fixOrientation(blob, await getOrientation(blob))
        }

        stream.getVideoTracks()[0].stop()
        setState({ processing: false })

        if (type === 'front') {
          emit('saveFront', image)
        } else {
          emit('saveSide', image)
        }
      } catch (exception) {
        console.error(`Error: ${exception}`)

        alert('Problems with photo. Please try again.')

        window.location.reload()
      }
    }
    // table flow
    async function setPhotoTableFlow(blob) {
      const { type } = props

      try {
        let image

        // for chrome >= 81 we dont need to use fixOrientation
        if (isPlatform('Android') && getChromeVersion() >= 81) {
          image = await imgToBase64(blob)
        } else {
          image = await fixOrientation(blob, await getOrientation(blob))
        }

        setState({ processing: false })

        $videoRef.value.play()

        if (type === 'front') {
          setFrontPhotoTableFlow(image)
        } else {
          setState({ isLastPhoto: true })
          playFinalSuccessPhotoAudio(image)
        }
      } catch (exception) {
        alert('Problems with setting photo. Please try again.')
      }
    }
    // table flow
    function setFrontPhotoTableFlow(img) {
      const { hardValidation } = props

      if (hardValidation.front && !hardValidation.side) {
        setState({ isLastPhoto: true })

        playFinalSuccessPhotoAudio(img)
      } else {
        emit('saveFront', img)

        setState({ activeAudioTrack: specifySuccessPhotoAudio() })
        const current = $audioRef.value
        current.load()
        current.play()
        current.playbackRate = PLAYSPEED
        current.addEventListener('ended', playAudioInstructions, { once: true })
      }
    }
    // table flow
    function specifySuccessPhotoAudio() {
      let track = audioSuccessPhotoF

      if (validation.hardValidationFS) {
        track = audioSuccessPhotoHVFSF
      }

      return track
    }

    // =====================================
    function specifyAudioTrack(tracks) {
      /* eslint-disable prefer-destructuring */
      let track = tracks[0]

      if (validation.hardValidationFS) {
        track = tracks[1]
      } else if (validation.hardValidationF) {
        track = tracks[2]
      } else if (validation.hardValidationS) {
        track = tracks[3]
      }

      return track
      /* eslint-enable prefer-destructuring */
    }
    // table flow
    function specifyAudioTrackFS(tracks) {
      /* eslint-disable prefer-destructuring */
      const { type } = props
      const isFrontPhoto = type === 'front'

      let track = isFrontPhoto ? tracks[0] : tracks[1]

      if (validation.hardValidationFS) {
        track = isFrontPhoto ? tracks[2] : tracks[3]
      } else if (validation.hardValidationF) {
        track = tracks[4]
      } else if (validation.hardValidationS) {
        track = tracks[5]
      }

      return track
      /* eslint-enable prefer-destructuring */
    }
    // table flow
    function playFirstAudio() {
      setState({
        activeAudioTrack: specifyAudioTrack(AUIDO_CASES.standPhone),
        tapScreen: false,
      })

      const current = $audioRef.value
      current.load()
      current.play()
      current.playbackRate = PLAYSPEED
      current.addEventListener('ended', proceedToGyroTimer, { once: true })
    }
    // table flow
    function proceedToGyroTimer() {
      setState({
        isFirstAudio: false,
        isGyroTimerAccess: true,
      })
      setGyroTimer()
    }
    // table flow
    function startGyroTimer() {
      setState({
        activeAudioTrackIndex: 0,
        photoTimerSecs: 4,
        isPhotoTimer: false,
      })

      removeAudioEventListeners()
      setGyroTimer()
    }
    // table flow
    function clearGyroTimer() {
      clearTimeout(state.gyroTimer)
      setState({ gyroTimer: null })
    }
    // table flow
    function setGyroTimer() {
      clearGyroTimer() // clear previous timer if any
      state.gyroTimer = setTimeout(playSuccessGyroAudio, 2000) // hold still for 2 seconds
    }
    // table flow
    function playSuccessGyroAudio() {
      setState({ activeAudioTrack: specifyAudioTrack(AUIDO_CASES.successGyro) })

      const current = $audioRef.value
      current.addEventListener('ended', playAudioInstructions, { once: true })
      current.load()
      current.play()
      current.playbackRate = PLAYSPEED
    }
    // table flow
    function countFrontAudioInstructions() {
      const { type } = props
      const { activeAudioTrackIndex } = state

      return !(type === 'front' && activeAudioTrackIndex >= 3 && !validation.isHardValidation)
    }
    // table flow
    function countSideAudioInstructions() {
      const { type } = props
      const { activeAudioTrackIndex } = state

      return !(type === 'side' && activeAudioTrackIndex >= 2 && !validation.isHardValidation)
    }
    // table flow
    function countFrontAudioInstructionsHV() {
      const { activeAudioTrackIndex } = state

      return !(validation.hardValidationF && activeAudioTrackIndex >= 3)
    }
    // table flow
    function countSideAudioInstructionsHV() {
      const { activeAudioTrackIndex } = state

      return !(validation.hardValidationS && activeAudioTrackIndex >= 3)
    }
    // table flow
    function countFrontAudioInstructionsHVF() {
      const { type } = props
      const { activeAudioTrackIndex } = state

      return !(type === 'front' && validation.hardValidationFS && activeAudioTrackIndex >= 2)
    }
    // table flow
    function countFrontAudioInstructionsHVS() {
      const { type } = props
      const { activeAudioTrackIndex } = state

      return !(type === 'side' && validation.hardValidationFS && activeAudioTrackIndex >= 2)
    }
    // table flow
    function countAudioInstructions() {
      if (!countFrontAudioInstructions()) {
        return false
      }

      if (!countSideAudioInstructions()) {
        return false
      }

      if (!countFrontAudioInstructionsHV()) {
        return false
      }

      if (!countSideAudioInstructionsHV()) {
        return false
      }

      if (!countFrontAudioInstructionsHVF()) {
        return false
      }

      return countFrontAudioInstructionsHVS()
    }
    // table flow
    function playAudioInstructions() {
      const { activeAudioTrackIndex } = state
      const { type } = props

      const current = $audioRef.value
      if (countAudioInstructions()) {
        current.addEventListener('ended', playAudioInstructions, { once: true })
      } else {
        current.addEventListener('ended', startPhotoTimer, { once: true })
      }

      const nextIndex = activeAudioTrackIndex + 1
      setState({
        activeAudioTrack: specifyAudioInstruction(activeAudioTrackIndex),
        activeAudioTrackIndex: nextIndex,
      })

      // if (
      //   (type === 'front' && activeAudioTrackIndex === 1) ||
      //   (validation.hardValidationS && activeAudioTrackIndex === 1)
      // ) {
      //   clearTimeout(moveTimer)
      //   moveTimer = setTimeout(() => {
      //     playCurrentAudio()
      //   }, 3000)
      // } else {
      //   playCurrentAudio()
      // }
      playCurrentAudio()
    }

    function playCurrentAudio() {
      const current = $audioRef.value
      current.load()
      current.play()
      current.playbackRate = PLAYSPEED
    }
    // table flow
    function specifyAudioInstruction(index) {
      let track

      switch (index) {
        case 0:
          track = specifyAudioTrackFS(AUIDO_CASES.firstInstruction)
          break
        case 1:
          track = specifyAudioTrackFS(AUIDO_CASES.secondInstruction)
          break
        case 2:
          track = specifyAudioTrackFS(AUIDO_CASES.thirdInstruction)
          break
        case 3:
          track = specifyAudioTrackFS(AUIDO_CASES.fourthInstruction)
          break
        default:
          alert('Problems with audio. Please try again.')
          window.location.reload()
      }

      return track
    }
    // table flow
    function startPhotoTimer() {
      setState({
        activeAudioTrackIndex: 0,
        activeAudioTrack: audioTimer,
      })

      const current = $audioRef.value
      current.addEventListener('canplay', showPhotoTimer, { once: true })
      playCurrentAudio()
    }
    // table flow
    function showPhotoTimer() {
      clearInterval(state.photoTimer)

      state.photoTimer = setInterval(() => {
        const { photoTimerSecs } = state

        if (photoTimerSecs > 0) {
          // eslint-disable-next-line no-shadow
          setState({
            photoTimerSecs: photoTimerSecs - 1,
            isPhotoTimer: true,
          })
        }

        if (photoTimerSecs === 1) {
          clearInterval(state.photoTimer)

          setState({
            isPhotoTimer: false,
            photoTimerSecs: 4,
            activeAudioTrack: audioPhotoShutter,
          })

          $videoRef.value.pause()

          const current = $audioRef.value
          current.addEventListener('ended', takePhoto, { once: true })
          playCurrentAudio()
        }
      }, 1000)
    }
    // table flow
    function clearPhotoTimer() {
      clearInterval(state.photoTimer)
      setState({
        photoTimer: null,
        isPhotoTimer: false,
      })
    }
    // table flow
    function playFinalSuccessPhotoAudio(img) {
      emit('saveSide', img)
      emit('turnOffCamera')
    }
    // table flow
    function removeAudioEventListeners() {
      const current = $audioRef.value

      current.removeEventListener('ended', proceedToGyroTimer, { once: true })
      current.removeEventListener('ended', playAudioInstructions, { once: true })
      current.removeEventListener('ended', startPhotoTimer, { once: true })
      current.removeEventListener('ended', takePhoto, { once: true })
      current.removeEventListener('canplay', showPhotoTimer, { once: true })
    }

    // ===========================
    function checkGyroOnce(event) {
      window.removeEventListener('deviceorientation', checkGyroOnce)

      if (event.beta === null || event.gamma === null) {
        const isTableFlow = props.isTableFlow

        if (isTableFlow) {
          gyroDisabledMsg()
        }
      } else {
        // android
        window.addEventListener('deviceorientation', handleDeviceOrientation)
      }
    }
    function isGyroActiveIphone() {
      const isTableFlow = props.isTableFlow

      if (isTableFlow) {
        gyroDisabledMsg()
      }
    }
    function gyroDisabledMsg() {
      emit('disableTableFlow')
    }
    function handleDeviceOrientation(event) {
      const { alpha, beta, gamma } = event
      const isTableFlow = props.isTableFlow

      updateGyroObject({ alpha, beta, gamma })

      if (isTableFlow) {
        setTimeout(() => {
          gyroscopePointerPosition(beta)
          normalizeDataTableFlow(gamma, beta)
        }, 50)
      } else {
        setTimeout(() => {
          gyroscopePointerPosition(beta)
          normalizeData(gamma, beta)
        }, 50)
      }
    }
    function gyroscopePointerPosition(value) {
      const result = (value * 360) / 180
      let position = result

      if (result < 0) {
        position = 0

        setState({ gyroscopePosition: position })

        return
      }

      if (result > 360) {
        position = 360

        setState({ gyroscopePosition: position })

        return
      }

      setState({ gyroscopePosition: position })
    }
    function normalizeDataTableFlow(_g, _b) {
      const { isGyroTimerAccess, isFirstAudio, isLastPhoto, gyroTimer, photoTimer } = state

      const b = Math.round(_b) // TODO check why this.b, this.g
      const g = Math.round(_g)

      rotY += (g - rotY) / 5
      rotX += (b - rotX) / 5

      if (b < 85 || b > 100) {
        gyroTimer && clearGyroTimer()
        photoTimer && clearPhotoTimer()

        // stop current sound
        if (!isFirstAudio && !isLastPhoto) {
          $audioRef.value.pause()
        }

        $videoRef.value.paused && $videoRef.value.play()

        setState({
          warning: true,
          activeAudioTrackIndex: 0,
          photoTimerSecs: 4,
        })
      } else {
        // interactive mode should listen to props.paused === false
        if (isGyroTimerAccess && !gyroTimer) {
          startGyroTimer()
        }

        setState({ warning: false })
      }
    }
    function normalizeData(_g, _b) {
      const b = Math.round(_b)
      const g = Math.round(_g)

      rotY += (g - rotY) / 5
      rotX += (b - rotX) / 5

      if (b < 75 || b > 105) {
        setState({ warning: true })
      } else {
        setState({ warning: false })
      }
    }
    function stopAudio() {
      removeAudioEventListeners()

      const audioElem = $audioRef.value
      audioElem.pause()
      audioElem.removeAttribute('src')
      audioElem.load()

      setState({
        activeAudioTrack: null,
      })
    }
    async function stopVideo() {
      const videoStream = $videoRef.value.srcObject
      if (videoStream) {
        const tracks = await videoStream.getTracks()
        tracks.forEach((track) => {
          track.stop()
          track.enabled = false
          videoStream.removeTrack(track)
        })
        stream = null
        $videoRef.value.srcObject = null
      }
    }
    // ===============================================

    async function requestDeviceOrientationPermission() {
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        try {
          const permission = await DeviceOrientationEvent.requestPermission()
          return permission === 'granted'
        } catch (err) {
          console.error(err)
          return false
        }
      } else {
        return false
      }
    }
    async function init() {
      const granted = await requestDeviceOrientationPermission()

      if (granted) {
        window.addEventListener('deviceorientation', handleDeviceOrientation)
      } else {
        window.addEventListener('deviceorientation', checkGyroOnce)
      }

      startStream()
    }

    function destroy() {
      setState({
        imgURI: null,
        processing: false,
        warning: false,
        isCameraAccess: true,
        camerasArr: [],
        activeCamera: -1,
        gyroscopePosition: 180,
        tapScreen: props.isTableFlow,

        activeAudioTrack: audioStandYourPhone,
        activeAudioTrackIndex: 0,

        isGyroTimerAccess: false,
        photoTimerSecs: 4,
        isPhotoTimer: false,
        isFirstAudio: true,
        isLastPhoto: false,
        debug: true,
      })
      clearGyroTimer()
      clearPhotoTimer()
      stopAudio()
      stopVideo()
      window.removeEventListener('deviceorientation', handleDeviceOrientation)
    }

    watch(
      () => props.type,
      (next) => {
        if (next === 'front') {
          playFirstAudio()
        } else {
          // destroy()
        }
      },
    )

    // watch(
    //   () => props.paused,
    //   (next, prev) => {
    //     const { isGyroTimerAccess, isFirstAudio, isLastPhoto, gyroTimer, photoTimer } = state

    //     if (prev && !next) {
    //       // unpause
    //       if (isGyroTimerAccess && !gyroTimer) {
    //         startGyroTimer()
    //       }
    //     } else if (next) {
    //       // pause
    //       gyroTimer && clearGyroTimer()
    //       photoTimer && clearPhotoTimer()

    //       if (!isFirstAudio && !isLastPhoto) {
    //         $audioRef.value.pause()
    //       }

    //       setState({
    //         activeAudioTrackIndex: 0,
    //         photoTimerSecs: 4,
    //       })
    //     }
    //   },
    // )

    onMounted(() => {
      init()
    })

    onUnmounted(() => {
      destroy()
    })

    return {
      isAndroid: isPlatform('Android'),
      $videoRef,
      $audioRef,
      state,
      playFirstAudio,
      changeCamera,
    }
  },
})
