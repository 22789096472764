


























































































































































































































import { defineComponent, useMeta, onMounted, onUnmounted, useRoute } from '@nuxtjs/composition-api'
import { usePageSettings } from '~/use/usePage'
export default defineComponent({
  name: 'Fabrics',
  setup() {
    const route = useRoute()

    useMeta({
      title: 'Our Fabrics',
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${route.value.path}`,
        },
      ],
    })
    usePageSettings()
    onMounted(() => {})

    onUnmounted(() => {})

    return {}
  },
  head: {},
})
