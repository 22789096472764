





















import { useMeta, defineComponent, useRoute } from '@nuxtjs/composition-api'
import { usePageSettings } from '~/use/usePage'
import Collection from '~/components/views/Collection.vue'

export default defineComponent({
  name: 'CollectionPage',
  components: { Collection },
  setup() {
    const route = useRoute()

    useMeta(() => {
      return {
        title: 'Collection',
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              "Shop the entire line of men's t-shirts. Multiple fits, fabrics and styles. All 100% made in Los Angeles.",
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content:
              "Shop the entire line of men's t-shirts. Multiple fits, fabrics and styles. All 100% made in Los Angeles.",
          },
          {
            hid: 'og:url',
            name: 'og:url',
            content: `${process.env.BASE_URL}${route.value.path}`,
          },
        ] as any,
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: `${process.env.BASE_URL}${route.value.path}`,
          },
        ],
      }
    })
    usePageSettings()
    return {}
  },
  head: {},
})
