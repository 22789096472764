//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ProductOrderDetails',
  setup() {
    return {}
  },
})
