import { render, staticRenderFns } from "./FitFlowInstructions.vue?vue&type=template&id=7ffc9c64&"
import script from "./FitFlowInstructions.vue?vue&type=script&lang=ts&"
export * from "./FitFlowInstructions.vue?vue&type=script&lang=ts&"
import style0 from "./FitFlowInstructions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PButton: require('/home/circleci/project/src/components/base/PButton.vue').default,InstructionsSlider: require('/home/circleci/project/src/components/fit-flow/InstructionsSlider.vue').default,SVGSpeakerIcon: require('/home/circleci/project/src/components/fit-flow/SVGSpeakerIcon.vue').default})
