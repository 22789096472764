
























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'

import { CollectionOptionCustom } from '~/use/user/useCollection'

export default defineComponent({
  name: 'OptionColor',
  props: {
    colorData: {
      type: Object as PropType<CollectionOptionCustom>,
      required: true,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const qty = ref(0)

    function updateQty(modifier: number) {
      qty.value += modifier

      emit('select', props.colorData.value, qty.value)
    }

    return {
      qty,
      updateQty,
    }
  },
})
