//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, toRefs } from '@nuxtjs/composition-api'
import { useBtn } from '~/use/useBtn'

export default defineComponent({
  name: 'PButtonCircle',
  components: {},
  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null,
    },
    width: {
      type: String,
      default: '150px',
    },
    height: {
      type: String,
      default: '150px',
    },
    active: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, context) {
    const { isTruthy, component, onClick, href } = useBtn(props, context)
    const { active } = toRefs(props)

    const computedClasses = computed(() => ({
      // color
      'text-black': true,
      border: true,
      'border-gray-300': !isTruthy(active.value),
      'border-transparent': isTruthy(active.value),
      'bg-white': isTruthy(active.value),
    }))

    return {
      component,
      computedClasses,
      href,
      onClick,
    }
  },
})
