


















































import { defineComponent, ref, Ref, useContext, onMounted } from '@nuxtjs/composition-api'
import VFNextStepButton from '../VFNextStepButton.vue'
import { UserFitPreference } from '~/services/api/db'

export default defineComponent({
  name: 'FitFlowFitPreference',
  components: { VFNextStepButton },
  emits: ['done'],
  setup(props, { emit }) {
    const { $gtag } = useContext() as any
    const selected: Ref<UserFitPreference | string> = ref('Fitted')

    function setSelected(value: UserFitPreference) {
      selected.value = value
    }

    function emitDone() {
      emit('done', { type: 'fitPreference', value: selected.value })
    }

    onMounted(() => {
      $gtag.event('fit_flow_4')
    })

    return {
      selected,
      setSelected,
      emitDone,
    }
  },
})
