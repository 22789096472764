import { render, staticRenderFns } from "./BackBtn.vue?vue&type=template&id=2ea3c720&"
import script from "./BackBtn.vue?vue&type=script&lang=ts&"
export * from "./BackBtn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PIcon: require('/home/circleci/project/src/components/base/PIcon.tsx').default,PButtonIcon: require('/home/circleci/project/src/components/base/PButtonIcon.vue').default})
