import { render, staticRenderFns } from "./production.vue?vue&type=template&id=54b6558c&scoped=true&"
import script from "./production.vue?vue&type=script&lang=ts&"
export * from "./production.vue?vue&type=script&lang=ts&"
import style0 from "./production.vue?vue&type=style&index=0&id=54b6558c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b6558c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SVGCircleText: require('/home/circleci/project/src/components/typography/SVGCircleText.vue').default,PageH1Title: require('/home/circleci/project/src/components/typography/PageH1Title.vue').default,PImgCDN: require('/home/circleci/project/src/components/base/PImgCDN.vue').default,NavCTAFitting: require('/home/circleci/project/src/components/nav/CTAFitting.vue').default,CTAMoreAbout: require('/home/circleci/project/src/components/base/CTAMoreAbout.vue').default})
