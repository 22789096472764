//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, toRefs, ref } from '@nuxtjs/composition-api'

import { useRipple } from '~/use/useRipple'
import { useBtn } from '~/use/useBtn'
import ArrowIcon from '~/components/icons/SvgArrowLong'
import CircularLoading from '~/components/base/CircularLoading'

const optionalBoolean = {
  type: Boolean,
  default: false,
  required: false,
}

export default defineComponent({
  name: 'PButtonIcon',
  components: {
    ArrowIcon,
    CircularLoading,
  },
  props: {
    loading: optionalBoolean,
    rounded: {
      type: Boolean,
      default: true,
    },
    to: {
      type: [Object, String],
      required: false,
      default: null,
    },
    ripple: {
      type: [String, Boolean],
      // default: '#C6B894',
      default: 'currentColor',
    },
    width: {
      type: String,
      default: '60px',
    },
    height: {
      type: String,
      default: '60px',
    },
    primary: optionalBoolean,
    secondary: optionalBoolean,
    outlined: optionalBoolean,
  },
  setup(props, context) {
    const { isTruthy, component, onClick, href } = useBtn(props, context)

    const { loading, rounded, primary, secondary, outlined } = toRefs(props)

    const computedClasses = computed(() => ({
      'is-loading': isTruthy(loading.value),

      // shape
      'rounded-full': isTruthy(rounded.value),

      'bg-black': isTruthy(primary.value),
      'text-white': isTruthy(primary.value),

      'bg-white': isTruthy(secondary.value),
      'text-black': isTruthy(secondary.value),

      'bg-transparent': !isTruthy(primary.value) && !isTruthy(secondary.value),
      'text-current': !isTruthy(primary.value) && !isTruthy(secondary.value),

      border: isTruthy(outlined.value),
      'border-current': isTruthy(outlined.value),
      'border-solid': isTruthy(outlined.value),

      'rounded-full': isTruthy(rounded.value),
    }))

    const rippleRef = ref(null)
    if (props.ripple) {
      useRipple(rippleRef, props.ripple)
    }
    return {
      computedClasses,
      component,
      rippleRef,
      href,
      onClick,
    }
  },
})
