


































































import { defineComponent, watch, ref, computed, useStore } from '@nuxtjs/composition-api'

import { useAuth } from '~/use/user/useAuth'
import { useNavBar } from '~/use/useNavBar'
import { useMessage } from '~/use/useMessage'

import Overlay from '~/components/base/Overlay.vue'
import SvgArrow from '~/components/icons/SvgArrowLong.vue'

export default defineComponent({
  name: 'NavDrawer',
  components: { Overlay, SvgArrow },
  setup() {
    const { main: mainMenu, sub: subMenu } = useNavBar()
    const store = useStore() as any
    const isFitted = computed(() => store.getters['user/isFitted'])
    const isAuth = computed(() => !!store.getters.token)
    const { notifyError } = useMessage()
    const { logoutUser } = useAuth()

    const isLoading = ref(false)

    const show = computed<boolean>({
      get() {
        return store.state.overlay === 'menu'
      },
      set(v) {
        store.commit('SET_OVERLAY', v ? 'menu' : '')
      },
    })

    const navIconName = ref('menu')
    watch(
      () => show.value,
      (val) => {
        setTimeout(() => {
          if (val) {
            navIconName.value = 'close'
          } else {
            navIconName.value = 'menu'
          }
        }, 10)
      },
    )

    function showLoginOverlay() {
      store.commit('SET_OVERLAY', 'login')
    }

    async function logOut() {
      try {
        isLoading.value = true
        await logoutUser()
      } catch (err) {
        notifyError(err)
      } finally {
        isLoading.value = false
      }
    }
    return {
      show,
      isLoading,

      navIconName,
      mainMenu,
      subMenu,
      logOut,
      isAuth,
      isFitted,
      showLoginOverlay,
    }
  },
})
