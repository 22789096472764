//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SvgFitCheckIcon',
}
