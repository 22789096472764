//
//
//
//
//
//
//
//

import { ref, defineComponent, onMounted, onUnmounted, watch } from '@nuxtjs/composition-api'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { v4 as UUID } from 'uuid'

export default defineComponent({
  name: 'ParallaxScrollPart',
  props: {
    to: {
      type: [Number, String],
      default: -100,
    },
  },
  setup(props) {
    const id = ref(`parallax-${UUID()}`)
    const wrapRef = ref(null)
    const tl = ref(null)

    function init() {
      tl.value = gsap.to(`#${id.value} > *`, {
        yPercent: parseInt(props.to),
        ease: 'none',
        scrollTrigger: {
          id: id.value,
          trigger: `#${id.value}`,
          // start: "top bottom", // the default values
          // end: "bottom top",
          scrub: true,
        },
      })
    }

    onMounted(() => {
      watch(
        () => wrapRef.value,
        (next) => next && setTimeout(() => init(), 1000),
        { immediate: true },
      )
    })

    onUnmounted(() => {
      const trigger = ScrollTrigger.getById(id.value)
      trigger && trigger.kill(true)
      tl.value && tl.value.kill()
    })

    return {
      id,
      wrapRef,
    }
  },
})
