




















import { ref, watch, reactive, defineComponent } from '@nuxtjs/composition-api'
import { getSrcSet } from '~/utils/shopify-helpers'
import { useIntersectionObserver } from '~/use/useIntersectionObserver'

export default defineComponent({
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '(max-width: 1199px) 450px, (max-width: 1799px) 650px, (min-width: 1800px) 750px',
    },
    alt: {
      type: String,
      default: '',
    },
    contain: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { observe } = useIntersectionObserver()
    const srcset = ref()
    const srcsetWebP = ref()
    const pimg = ref(null)
    const imgClass = ref('p-fade-in--initial')
    const imageState = reactive({
      inview: false,
      loaded: false,
    })

    const hash = ref(Date.now())

    // preload images 125vh offset top and bottom
    observe(pimg, {
      rootMargin: '125% 0% 125% 0%',
      once: true,
      handler: () => {
        watch(
          () => props.src,
          (src) => {
            imageState.loaded = false
            if (src) {
              hash.value = Date.now()
              srcset.value = getSrcSet(src)
              srcsetWebP.value = getSrcSet(src, 'webp')
            }
          },
          { immediate: true },
        )
      },
    })

    // fade in images in viewport
    observe(pimg, {
      once: true,
      handler: () => {
        imageState.inview = true
      },
    })

    const handleLoad = () => {
      imageState.loaded = true
    }

    let tries = 1
    const handleError = () => {
      imgClass.value = ''
      setTimeout(() => {
        if (tries < 4) {
          tries++
          hash.value = Date.now()
        }
      }, 3000)
    }

    watch(
      () => imageState,
      ({ inview, loaded }) => {
        if (inview && loaded) {
          imgClass.value = 'animated p-fade-in'
        } else {
          imgClass.value = 'p-fade-in--initial'
        }
      },
      { immediate: true, deep: true },
    )

    return {
      pimg,
      hash,
      imgClass,
      srcset,
      srcsetWebP,
      handleLoad,
      handleError,
    }
  },
})
