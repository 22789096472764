






















































import {
  useContext,
  useMeta,
  computed,
  defineComponent,
  onMounted,
  useStore,
  useRoute,
  useAsync,
  watch,
} from '@nuxtjs/composition-api'

import { usePageSettings } from '~/use/usePage'
import { useMessage } from '~/use/useMessage'

export default defineComponent({
  name: 'Product',
  components: {},
  setup() {
    const { $gtag } = useContext() as any
    const route = useRoute()
    const store = useStore() as any
    const { showError, notifyError } = useMessage()

    const product = useAsync(() => {
      try {
        const product = require('~/static/generated/products/gift-card.json')
        return product
      } catch (err) {
        notifyError(err)
      }
    }, route.value.fullPath)

    const cartMessage = computed(() => store.getters['cart/cartMessage'])

    usePageSettings({
      navColors: {
        default: {
          textColor: 'white',
        },
      },
    })
    useMeta(() => {
      return {
        title: 'Gift Card',
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              "Shop the entire line of men's t-shirts. Multiple fits, fabrics and styles. All 100% made in Los Angeles.",
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content:
              "Shop the entire line of men's t-shirts. Multiple fits, fabrics and styles. All 100% made in Los Angeles.",
          },
          {
            hid: 'og:url',
            name: 'og:url',
            content: `${process.env.BASE_URL}${route.value.path}`,
          },
        ] as any,
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: `${process.env.BASE_URL}${route.value.path}`,
          },
        ],
      }
    })

    function showHelpOverlay() {
      store.commit('SET_OVERLAY', 'gift-card-help')
    }
    function showBuyOverlay() {
      store.commit('SET_OVERLAY', 'gift-card-buy')
    }

    watch(
      () => product.value,
      (next, prev) => {
        if (next) {
          const { title, id, collectionNumber } = next

          $gtag.event('view_item', {
            items: [
              {
                item_name: title,
                item_id: id,
                item_category: collectionNumber,
              },
            ],
          })
        }
      },
      { immediate: true },
    )

    onMounted(() => {})

    return {
      cartMessage,
      showHelpOverlay,
      showBuyOverlay,
      product,
    }
  },
  head: {},
})
