














































import { defineComponent, computed, Ref, ref, watch, useContext, useAsync, useStore } from '@nuxtjs/composition-api'
import { useMessage } from '~/use/useMessage'
import { useCollection } from '~/use/user/useCollection'
import { useCart } from '~/use/useCart'
import { useDelay } from '~/use/useDelay'
import { IProductVariant, IProduct, ICollection, IProductOptionSelected } from '~/services/api/db-types-shop'

export default defineComponent({
  name: 'PersonalShopView',
  props: {
    fit: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { $content, error } = useContext() as any
    const { notifyError } = useMessage()
    const store = useStore() as any
    const { cartAdd } = useCart()
    const { collections, bestSeller } = useCollection(props.fit, { bestSeller: 'crewneck-short-sleeve-medium-weight' })

    function getVariantByColor(colorValue: string, variants: IProductVariant[]) {
      return variants.find((v: IProductVariant) =>
        v.selectedOptions.find((o: IProductOptionSelected) => o.name === 'color' && o.value === colorValue),
      )
    }
    const quickBuyItems: Ref<any[]> = computed(() => {
      const p: IProduct = bestSeller.value

      if (p) {
        const availableVariants: IProductVariant[] = []
        const opticWhite = getVariantByColor('Optic White', p.variants)
        const houseBlack = getVariantByColor('House Black', p.variants)
        opticWhite && opticWhite.available && availableVariants.push(opticWhite)
        houseBlack && houseBlack.available && availableVariants.push(houseBlack)

        const items: any[] = availableVariants.map((v: IProductVariant) => {
          return {
            id: v.id,
            image: v.image.src,
            color: v.selectedOptions.find((o: IProductOptionSelected) => o.name === 'color')?.value,
            price: v.price,
            ...p.params, // style, sleeve, fabric
            variant: v,
          }
        })

        return items
      } else {
        return []
      }
    })
    const loadingVariant = ref('')

    function showCartMessage(message: string) {
      store.commit('cart/SHOW_MESSAGE', message)
      setTimeout(() => store.commit('cart/SHOW_MESSAGE', ''), 10000)
    }

    async function quickBuy(variant: IProductVariant) {
      loadingVariant.value = variant.id

      await cartAdd(variant)
      await useDelay(1000)
      store.commit('SET_OVERLAY', '')
      await useDelay(1500)
      showCartMessage('ADDED 1 ITEM TO CART')

      loadingVariant.value = ''
    }

    return {
      collections,
      quickBuyItems,
      quickBuy,
      loadingVariant,
    }
  },
})
