



















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  layout({ redirect, route }) {
    const match = route.path.match(/magic-link\/(.*)$/)

    if (match && match[1]) {
      redirect(301, `/magic-link?token=${match[1]}`)
    }
    return 'layout'
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {}
  },
  head() {
    return {
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow, noarchive',
        },
      ],
    }
  },
})
